@use '@material/button';
@use '@material/linear-progress';
@use '@material/textfield';
@use '@material/select';
@use '@material/checkbox';
@use '@material/textfield/icon';
@use '@material/radio';
@use '@material/slider';
@use '@material/tooltip';
@use '@material/dialog';
@use '@material/dialog/mdc-dialog';
@import 'variables';
@import 'mixins';

@mixin bottom-line-deleted() {
    .mdc-line-ripple {
        display: none;
    }
}

@mixin select-text-center() {
    .mdc-select__selected-text, .mdc-list-item__primary-text {
        width: fit-content;
        text-align: center;
    }

    .mdc-list-item__content,.mdc-select__selected-text-container {
        display:flex;
        justify-content: center;
    }
}

$offwhite: rgba(255, 255, 255, .6);
$offgreen: rgba(57, 226, 157, .7);
$offred: rgba(253, 74, 74, .75);
$offgreen-disabled: rgba(57, 226, 157, .2);
$offred-disabled: rgba(253, 74, 74, .2);
$offblue-disabled: rgba(87, 98, 213, .2);

body {
    --mdc-theme-primary: rgba(87, 98, 213, 1);
    --mdc-theme-secondary: rgba(76, 96, 133, 1);
    --mdc-theme-on-primary: $primarycolor;
    --mdc-typography-font-family: $primaryfont;
    --mdc-background-primary: rgba(0, 0, 0, 0);
}

.mdc-select {
    .mdc-menu-surface {
        margin-top: 3px;
    }

    &:not(.mdc-text-field--disabled):not(.cg-input--success):not(.cg-input--error):not(.cg-input--warning):not(.override-bg):not(.skeleton-loading) {
        background-color: unset !important;
    }
}

.mdc-menu-surface {
    overflow: hidden;
}

.page-host-content:has(#swap) .mdc-menu-surface{
    min-width: 100%;
}

:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple:before {
    background-color: unset;
}

.mdc-list {
    padding: 0;
}

.mdc-list-item.mdc-list-item--with-one-line {
    height: 50px;
}

.mdc-select--outlined .mdc-select__anchor .mdc-select__selected-text:before {
    content: "" !important;
}

.mdc-select:not(.mdc-select--disabled) .mdc-select__icon {
    color: $white;
    font-size: $regular;
    letter-spacing: .68px;
}

.page-host-content:has(#swap) .mdc-list-item__primary-text {
    @include font(14px, 400, 16px, .68px, $white);
}

.mdc-list-item__primary-text {
    @include font(14px, 400, 16px, 0.035px, $color-grey-100);
}

.mdc-list-item--selected .mdc-list-item__primary-text {
    font-weight: 400;
    color: $color-grey-100;
}
.page-host-content:has(#swap) .mdc-list-item--selected .mdc-list-item__primary-text {
    color: rgba(255, 255, 255, 1);
}

.mdc-button {
    height: 50px;
}

.mdc-button--touch {
    margin-block: 0;
    height: initial;
}

.mdc-touch-target-wrapper {
    .mdc-button {
        min-width: unset;
        padding: unset;
        color: inherit;
        font-weight: inherit;
    }

    .mdc-button--touch {
        margin-block: 0;
    }
}

.mdc-select__anchor {
    border-radius: 4px;
}

.page-host-content:has(#swap) .mdc-text-field--filled, 
    .mdc-select--outlined .mdc-select__anchor, 
    .mdc-select--filled .mdc-select__anchor, 
    .mdc-text-field--outlined:not(.mdc-text-field--textarea){
    height: 52px;
}

.mdc-text-field--filled, 
.mdc-select--outlined .mdc-select__anchor, 
.mdc-select--filled .mdc-select__anchor, 
.mdc-text-field--outlined:not(.mdc-text-field--textarea) {
    height: 52px;
}

.mdc-slider .mdc-slider__value-indicator {
    background: $primaryborder;
    opacity: 1;

    &::before {
        border-top-color: $primaryborder;
    }
}

//Arrow rotate
.mdc-select .mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
    display: none;
}

.mdc-select .mdc-select__dropdown-icon .mdc-select__dropdown-icon-active {
    display: none;
}

.mdc-button {
    text-transform: unset;
}

.mdc-list-item__content {
    z-index: 10;
}

.mdc-select--filled .mdc-select__anchor {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.mdc-text-field__affix--suffix {
    padding-right: 12px;
    color: $white !important;
}

.mdc-text-field {
    &__ripple:after,
    &__ripple:before {
        background-color: unset !important;
    }
}

@media (width <= $desktop) {
    .mdc-button {
        &__ripple:after,
        &__ripple:before {
            background-color: unset !important;
        }
    }
}

:not(.mdc-select--disabled).cg-input--success .mdc-select__anchor,
:not(.mdc-select--disabled).cg-input--error .mdc-select__anchor {
    background-color: unset !important;
}

.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__trailing {
    border: 0;
}

.mdc-button-blue-filled {
    @include button.filled-accessible($hoverblue);
    @include button.ink-color($color-grey-100);
    @include button.disabled-container-fill-color($hoverblue);
    @include button.disabled-ink-color($color-grey-100);
    appearance: none;
    letter-spacing: 0.15px;
    text-align: center;
    -webkit-appearance: none;

    &:hover {
        @include button.filled-accessible($primary-700);
    }

    &:active {
        @include button.filled-accessible($lightpurple);
    }
}

.button-blue-filled {
    @include button.filled-accessible($blue);
    @include button.ink-color($white);
    @include button.disabled-container-fill-color($blue);
    @include button.disabled-ink-color($white);
    appearance: none;
    letter-spacing: 0.15px;
    text-align: center;
    -webkit-appearance: none;
}

.button-blue-filled:hover {
    @include button.filled-accessible($hoverblue);
}

.button-blue-filled:disabled {
    opacity: 0.2;
}

.button-blue-filled:not(:disabled) {
    color: var(--Color-Grey-100);
}

.button-blue-transparent-no-border {
    @include button.filled-accessible($transparent);
    @include button.ink-color($hoverblue);
    @include button.outline-width(0);
    @include button.outline-color($transparent);
    appearance: none;
    letter-spacing: 0.15px;
    text-align: center;
    -webkit-appearance: none;
}

.button-green-filled {
    @include button.filled-accessible($green);
    @include button.ink-color($primarybackground);
    letter-spacing: 0.15px;
    text-align: center;
}

.button-green-filled:hover {
    @include button.filled-accessible($green);
}

.button-gray-filled {
    @include button.filled-accessible(rgba(63, 66, 84, 1));
    @include button.ink-color($white);
    @include button.container-fill-color(('disabled': rgba(63, 66, 84, 1)));
    @include button.disabled-ink-color(rgba(255, 255, 255, .2));
    letter-spacing: 0.15px;
    text-align: center;

    &--darker {
        @include button.filled-accessible(rgb(59, 60, 75));
    }
}

.button-gray-filled:hover {
    @include button.filled-accessible(rgba(71, 74, 90, 1));
    @include button.ink-color($white);
}

.button-transparent {
    @include button.filled-accessible($transparent);
    @include button.ink-color(rgba(255, 255, 255, 0.5));
    @include button.outline-width(1px);
    letter-spacing: 0.15px;
    text-align: center;
}

.button-transparent:hover {
    @include button.ink-color($white);
}

.button-transparent-outlined {
    @include button.ink-color(rgba(255, 255, 255, 0.5));
    @include button.outline-width(1px);
    border-style: solid;
    border-width: 2px;
    letter-spacing: 0.15px;
    text-align: center;
}

.button-transparent-outlined:hover {
    border-color: rgba(255, 255, 255, 0.6);
    color: rgba(255, 255, 255, 0.6);
}

.button-white-transparent {
    @include button.filled-accessible($darkergray);
    @include button.outline-color($transparent);
    @include button.outline-width(1px);
    @include button.ink-color($mediumgray);
    letter-spacing: 0.15px;
    text-align: center;
}

.button-white-transparent:hover {
    @include button.filled-accessible($darkergray);
    @include button.outline-color($transparent);
    @include button.ink-color($mediumgray);
}

.button-green-low-transparency {
    @include button.filled-accessible(rgba(57, 226, 157, .1));
    @include button.outline-color($transparent);
    @include button.outline-width(0);
    @include button.ink-color($green);
    @include button.container-fill-color(('disabled': rgba(57, 226, 157, .05)));
    @include button.disabled-ink-color($offgreen-disabled);
    letter-spacing: 0.15px;
    text-align: center;
}

.button-green-low-transparency:hover {
    @include button.filled-accessible(rgba(57, 226, 157, .2));
    @include button.ink-color($green);
}

.button-green-transparent {
    @include button.filled-accessible($transparent);
    @include button.outline-color($offgreen);
    @include button.outline-width(1px);
    @include button.ink-color($offgreen);
    @include button.container-fill-color(('disabled': $transparent));
    @include button.disabled-ink-color($offgreen-disabled);
    @include button.disabled-outline-color($offgreen-disabled);
    letter-spacing: 0.15px;
    text-align: center;
}

.button-green-transparent:hover {
    @include button.outline-color($green);
    @include button.ink-color($green);
}

.button-red-low-transparency {
    @include button.filled-accessible(rgba(253, 74, 74, .1));
    @include button.outline-color($transparent);
    @include button.outline-width(0);
    @include button.ink-color($red);
    @include button.container-fill-color(('disabled': rgba(253, 74, 74, .05)));
    @include button.disabled-ink-color($offred-disabled);
    letter-spacing: 0.15px;
    text-align: center;
}

.button-red-low-transparency:hover {
    @include button.filled-accessible(rgba(253, 74, 74, .2));
    @include button.ink-color($red);
}

.button-red-transparent {
    @include button.filled-accessible($transparent);
    @include button.outline-color($offred);
    @include button.outline-width(1px);
    @include button.ink-color($offred);
    @include button.container-fill-color(('disabled': $transparent));
    @include button.disabled-ink-color($offred-disabled);
    @include button.disabled-outline-color($offred-disabled);
    letter-spacing: 0.15px;
    text-align: center;

    .mdc-button__ripple:after,
    .mdc-button__ripple:before {
        background-color: transparent;
    }
}

.button-red-transparent:hover {
    @include button.outline-color($red);
    @include button.ink-color($red);
}

.button-blue-low-transparency {
    @include button.filled-accessible(rgba(87, 98, 213, .1));
    @include button.outline-color($transparent);
    @include button.outline-width(0);
    @include button.ink-color($blue);
    @include button.container-fill-color(('disabled': rgba(87, 98, 213, .05)));
    @include button.disabled-ink-color($offblue-disabled);
    letter-spacing: 0.15px;
    text-align: center;
}

.button-blue-low-transparency:hover {
    @include button.filled-accessible(rgba(87, 98, 213, .2));
    @include button.ink-color($blue);
}

.button-blue-transparent {
    @include button.filled-accessible($transparent);
    @include button.outline-color($blue);
    @include button.outline-width(1px);
    @include button.ink-color($blue);
    letter-spacing: 0.15px;
    text-align: center;
}

.button-slate-blue-off-transparent {
    @include button.filled-accessible($slateblue-off-low-opacity);
    @include button.outline-color($transparent);
    @include button.outline-width(0);
    @include button.ink-color($white);
    @include button.container-fill-color(('disabled': rgba(87, 98, 213, .05)));
    @include button.disabled-ink-color($slateblue-off-low-opacity);
    letter-spacing: 0.15px;
    text-align: center;

    .mdc-button__ripple:after,
    .mdc-button__ripple:before {
        background-color: transparent;
    }
}

.input-default {
    @include textfield.fill-color(red);
    @include textfield.outline-color($primaryborder);
    @include textfield.focused-outline-color($primaryborder);
    @include textfield.hover-outline-color($primaryborder);
    @include textfield.ink-color($white);
    @include textfield.label-color($white);
    @include textfield.disabled-ink-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-label-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color(rgba(255, 255, 255, 0.55));
    @include textfield.disabled-bottom-line-color($transparent);
    @include textfield.disabled-placeholder-color($mediumgray);
    @include textfield.placeholder-color($mediumgray);
    @include textfield.caret-color(rgba(255, 255, 255, 0.3));
    border: 0.5px;
    border-radius: 4px;

    &.mdc-text-field--disabled {
        opacity: .3;
    }
}

.input-whole-transparent {
    @include textfield.fill-color($transparent);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include textfield.ink-color($white);
    @include textfield.label-color($white);
    @include textfield.disabled-ink-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-label-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color($transparent);
    @include textfield.placeholder-color($mediumgray);
    @include textfield.caret-color(rgba(255, 255, 255, 0.3));
    @include textfield.bottom-line-color($transparent);
}

.input-password {
    @include textfield.fill-color($slateblue-off-low-opacity);
    @include textfield.ink-color($white);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include icon.trailing-icon-color($slateblue-off-low-opacity);
    @include textfield.label-color($white);
    @include textfield.disabled-ink-color($white);
    @include textfield.disabled-fill-color($slateblue-off-low-opacity);
    @include textfield.disabled-label-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color($transparent);
    @include textfield.disabled-placeholder-color($mediumgray);
    @include textfield.bottom-line-color($transparent);
    @include textfield.line-ripple-color($transparent);
    @include textfield.hover-bottom-line-color($transparent);
    @include textfield.placeholder-color($mediumgray);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color(rgba(255, 255, 255, 0.3));
    border: 0.5px;
    border-radius: 4px;

    &.mdc-text-field--focused {
        background-color: $slateblue-off-high-opacity;

        input:-webkit-autofill,
        input:-webkit-autofill:active,
        input:-webkit-autofill:first-line,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:hover {
            box-shadow: inset 0 0 0 1000px rgba(66, 77, 106, 1);
        }

        &.blue-background-container input:-webkit-autofill,
        &.blue-background-container input:-webkit-autofill:active,
        &.blue-background-container input:-webkit-autofill:first-line,
        &.blue-background-container input:-webkit-autofill:focus,
        &.blue-background-container input:-webkit-autofill:hover {
            box-shadow: inset 0 0 0 1000px rgba(59, 72, 105, 1);
        }
    }

    .mdc-text-field__input:not(textarea) {
        overflow: hidden;
        padding-right: 19px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.mdc-text-field--disabled {
        opacity: .3;
    }
}

.mdc-text-field__input:not(textarea) {
    $icon-classes: ('.circle-icon', '.mini-spinner-icon', '.white-x-mark');
    @each $class in $icon-classes {
        *:has(&):has(> #{$class}) & {
            $icon-size: 16px;
            padding-right: calc($icon-size + 16px);
            width: 100% !important;
        }
    }
}

button.input-password::not(.mdc-text-field--disabled) {
    background-color: $slateblue-off-low-opacity !important;
}

.input-profile {
    @include textfield.fill-color($slateblue-off-low-opacity);
    @include textfield.ink-color($white);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include icon.trailing-icon-color($slateblue-off-low-opacity);
    @include textfield.label-color($white);
    @include textfield.disabled-ink-color(rgba(114, 118, 125, 0.75));
    @include textfield.disabled-fill-color(rgba(76, 96, 133, 0.2));
    @include textfield.disabled-label-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color($transparent);
    @include textfield.disabled-placeholder-color($mediumgray);
    @include textfield.placeholder-color($mediumgray);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color(rgba(87, 98, 213, 1));
    height: 35px !important;
    font-size: $small !important;
    border-radius: 6px;
    border-top: 2px solid $success-green;

    .mdc-text-field__input {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.mdc-text-field--disabled {
        opacity: .3;
    }
}

.input-password:hover:not(.mdc-text-field--focused) {
    @include textfield.fill-color($slateblue-off-medium-opacity);

    input:-webkit-autofill,
    input:-webkit-autofill:active,
    input:-webkit-autofill:first-line,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:hover {
        box-shadow: inset 0 0 0 1000px rgba(59, 68, 92, 1);
    }

    &.blue-background-container input:-webkit-autofill,
    &.blue-background-container input:-webkit-autofill:active,
    &.blue-background-container input:-webkit-autofill:first-line,
    &.blue-background-container input:-webkit-autofill:focus,
    &.blue-background-container input:-webkit-autofill:hover {
        box-shadow: inset 0 0 0 1000px rgba(51, 59, 91, 1);
    }
}

.input-background {
    @include textfield.disabled-fill-color(rgba(76, 96, 133, 0.1));
    @include textfield.fill-color($slateblue-off-low-opacity);
    @include textfield.outline-color($slateblue-off-low-opacity);
    @include textfield.focused-outline-color(rgba(255, 255, 255, 0.6));
    @include textfield.hover-outline-color(rgba(255, 255, 255, 0.6));
    @include textfield.ink-color($white);
    @include textfield.label-color($white);
    @include textfield.placeholder-color($mediumgray);
    @include textfield.disabled-ink-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-label-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color(rgba(255, 255, 255, 0.55));
    @include textfield.disabled-bottom-line-color(transparent);
    @include textfield.bottom-line-color($transparent);
    @include textfield.line-ripple-color($transparent);
    @include textfield.hover-bottom-line-color($transparent);
    @include textfield.disabled-placeholder-color(rgba(255, 255, 255, 0.3));
    @include textfield.suffix-color($white);
    @include textfield.caret-color(rgba(255, 255, 255, 0.3));
    border-radius: 4px;
}

.input-pagination {
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($primaryborder);
    @include textfield.hover-outline-color($primarybackground);
    @include textfield.density(-4);
    @include textfield.ink-color($white);
    @include textfield.placeholder-color($white);
    @include textfield.label-color($white);
    border-radius: 4px;
    height: 35px;
    width: 45px;

    .mdc-text-field__input:focus::placeholder {
        color: transparent !important;
    }
}

.page-host-content:not(:has(#swap)) .search-bar-mdc {
        @include textfield.bottom-line-color($transparent);
        @include textfield.hover-bottom-line-color($transparent);
        @include textfield.line-ripple-color($transparent);
        @include textfield.fill-color($color-accent-700);
        @include textfield.outline-color(transparent);
        @include textfield.focused-outline-color(transparent);
        @include textfield.hover-outline-color(transparent);
        @include textfield.ink-color($white);
        @include textfield.label-color($color-grey-500);
        @include textfield.disabled-ink-color(rgba(255, 255, 255, 0.35));
        @include textfield.disabled-label-color(rgba(255, 255, 255, 0.35));
        @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
        @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
        @include textfield.disabled-outline-color(rgba(255, 255, 255, 0.55));
        @include textfield.disabled-fill-color($color-accent-700);
        @include textfield.placeholder-color($color-grey-500);
        border: 0.5px;
        border-radius: 4px;
    
        .mdc-text-field__ripple:after,
        .mdc-text-field__ripple:before {
            background-color: unset;
        }
    
        .mdc-text-field__input::placeholder {
            font-size: $regular;
        }
    
        &:hover {
            @include textfield.fill-color($color-accent-600);
        }
    
        &.mdc-text-field--focused {
            @include textfield.fill-color($color-accent-500);
        }
    }



.search-bar {
    @include textfield.bottom-line-color($transparent);
    @include textfield.hover-bottom-line-color($transparent);
    @include textfield.line-ripple-color($transparent);
    @include textfield.fill-color($slateblue-off-low-opacity);
    @include textfield.outline-color($slateblue-off-low-opacity);
    @include textfield.focused-outline-color(rgba(255, 255, 255, 0.6));
    @include textfield.hover-outline-color(rgba(255, 255, 255, 0.6));
    @include textfield.ink-color($white);
    @include textfield.label-color(rgba(255, 255, 255, 0.6));
    @include textfield.disabled-ink-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-label-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color(rgba(255, 255, 255, 0.55));
    @include textfield.disabled-fill-color($slateblue-off-lower-opacity);
    @include textfield.placeholder-color($mediumgray);
    border: 0.5px;
    border-radius: 4px;

    .mdc-text-field__ripple:after,
    .mdc-text-field__ripple:before {
        background-color: unset;
    }

    &:hover {
        @include textfield.fill-color($slateblue-off-medium-opacity);
    }

    &.mdc-text-field--focused {
        @include textfield.fill-color($slateblue-off-high-opacity);
    }
}

.skill-input {
    @include textfield.bottom-line-color($transparent);
    @include textfield.hover-bottom-line-color($transparent);
    @include textfield.line-ripple-color($transparent);
    @include textfield.fill-color($slateblue-off-medium-opacity);
    @include textfield.outline-color($slateblue-off-medium-opacity);
    @include textfield.focused-outline-color($gray);
    @include textfield.hover-outline-color($gray);
    @include textfield.ink-color($white);
    @include textfield.label-color($gray);
    @include textfield.disabled-ink-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-label-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color(rgba(255, 255, 255, 0.55));
    @include textfield.placeholder-color($mediumgray);
    border: 0.5px;
    border-radius: 4px;

    .mdc-text-field__ripple:after,
    .mdc-text-field__ripple:before {
        background-color: unset;
    }
}

.skill-input:hover {
    @include textfield.fill-color($slateblue-off-high-opacity);
}

.input-black {
    @include textfield.fill-color($transparent);
    @include textfield.outline-color(rgba(250, 252, 255, 0.5));
    @include textfield.focused-outline-color(rgba(250, 252, 255, 0.5));
    @include textfield.hover-outline-color(rgba(250, 252, 255, 0.5));
    @include textfield.ink-color($white);
    @include textfield.label-color($white);
    @include textfield.disabled-ink-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-label-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color(rgba(255, 255, 255, 0.35));
    @include textfield.placeholder-color($mediumgray);
    border: 0.5px;
}

.checkbox {
    @include checkbox.container-colors(
        $unmarked-stroke-color: $transparent,
        $unmarked-fill-color: $slateblue-off-low-opacity,
        $marked-stroke-color: $transparent,
        $marked-fill-color: $darkergreen,
        $generate-keyframes: false);
    @include checkbox.disabled-container-colors($unmarked-fill-color: rgba(39, 44, 57, 1));
    @include checkbox.ink-color($green);
    @include checkbox.focus-indicator-color($transparent);
    @include checkbox.disabled-ink-color(rgba(43, 79, 76, 1));

    &:hover {
        .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
            background-color: $slateblue-off-medium-opacity;
        }
    }

    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
        border-color: $transparent;
        background-color: $darkergreen;
    }

    .mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background,
    .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:not(:checked)~.mdc-checkbox__background {
        animation-duration: unset;
        animation-timing-function: unset;
    }

    .mdc-checkbox__ripple:after,
    .mdc-checkbox__ripple:before {
        background-color: unset;
    }
}

.select-default {
    --mdc-theme-surface: rgba(48, 49, 65, 1);
    --mdc-theme-on-surface: $white;
    $outline: (default: rgba(255, 255, 255, 0.5), hover: rgba(255, 255, 255, 0.6), focus: rgba(255, 255, 255, 0.6));
    $label: (default: $gray, hover: $gray, focus: $gray);
    @include select.container-fill-color(rgba(48, 49, 65, 1));
    @include select.label-color($label);
    @include select.ink-color($white);
    @include select.dropdown-icon-color($white);
    @include select.icon-color($white);
    @include select.outline-color(('default': $transparent, 'hover': $transparent, 'focus': $transparent));
    @include select.filled-shape-radius(12px);
}

.select-blue-filled {
    --mdc-theme-surface: rgba(76, 96, 133, 1);
    --mdc-theme-on-surface: $white;
    @include select.container-fill-color(('default': $primaryborder, 'disabled': $primaryborder));
    @include select.label-color(('default': $white, 'hover': $white, 'focus': $white));
    @include select.ink-color(('default': $white, 'disabled': $white));
    @include select.dropdown-icon-color(('default': $white, 'hover': $white, 'focus': $white));
    @include select.label-floating-color(rgba(0, 0, 0, 0));
    @include select.outline-color(('default': $transparent, 'hover': $transparent, 'focus': $transparent));

    &.mdc-select--activated .mdc-select__anchor {
        background-color: $primaryborder !important;
    }

    .mdc-ripple-surface:hover::before,
    .mdc-ripple-surface:hover .mdc-list-item__ripple::before,
    .mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
        background: rgb(70, 87, 120, .4);
        opacity: 1;
    }
}

.select-promotion {
    @include bottom-line-deleted();
    @include select-text-center();
    $size: 45px;

    .mdc-menu-surface {
        background-color: #37384B;
        border-radius: 4px;
        box-shadow: 0px 3px 0px 0px rgb(48, 49, 64), 0px 6px 10px 4px rgba(0,0,0,0.1);
    }

    .mdc-list-item {
        height: $size;
        padding: 0 15px 0 15px;
    }

    .mdc-select__selected-text, .mdc-list-item__primary-text {
        font-size: $large !important;
        font-weight: 700 !important;
        line-height: 21px !important;
        letter-spacing: .57px !important;
    }

    .mdc-select {
        &__ripple {
            display: none;
        }

        &__dropdown-icon {
            margin-left: 10px;
            margin-right: 21px;
            width: fit-content;
        }

        &__selected-text-container {
            flex-grow: unset;
            height: auto;
        }

        &__anchor {
            display:flex;
            align-items: center;
            justify-content: center;
            background-color: transparent !important;
            width: 100%;
        }

        &__anchor {
            height: $size;
            padding-left: 19px;
        }
    }
}

.select-blue-filled:hover {
    @include select.container-fill-color(rgba(70, 87, 120, 1));
}

.select-background-outlined {
    --mdc-theme-surface: rgb(48, 49, 65);
    --mdc-theme-on-surface: $white;
    --mdc-theme-primary: $purple-navy;
    @include select.container-fill-color(('default': $slateblue-off-low-opacity, 'disabled': $slateblue-off-low-opacity));
    @include select.label-color(('default': $gray, 'hover': $gray, 'focus': $gray));
    @include select.ink-color(('default': $white, 'disabled': $white));
    @include select.dropdown-icon-color(('default': $white, 'hover': $white, 'focus': $white));
    @include select.outline-color(('default': $transparent, 'hover': $transparent, 'focus': $transparent));

    .mdc-select--disabled {
        background-color: $slateblue-off-low-opacity;
        @include select.ink-color((disabled: $white));
    }

    .mdc-ripple-surface:hover::before,
    .mdc-ripple-surface:hover .mdc-list-item__ripple::before {
        background: $blue;
        opacity: 0.1;
    }

    .mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
        background: $blue;
        opacity: 0.2;
    }

    .mdc-menu-surface::-webkit-scrollbar-thumb {
        background-color: $blue;
    }

    //Lookup element
    @include textfield.fill-color($slateblue-off-low-opacity);
    @include textfield.ink-color($white);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include icon.trailing-icon-color($white);
    @include textfield.label-color($gray);
    @include textfield.disabled-ink-color($white);
    @include textfield.disabled-fill-color($primaryborder);
    @include textfield.disabled-label-color($white);
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color($transparent);
    @include textfield.disabled-placeholder-color($mediumgray);
    @include textfield.bottom-line-color($transparent);
    @include textfield.line-ripple-color($transparent);
    @include textfield.hover-bottom-line-color($transparent);
    @include textfield.placeholder-color($mediumgray);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color(rgba(255, 255, 255, 0.3));
    border: 0.5px;
    border-radius: 4px;

    &.mdc-text-field--disabled {
        opacity: .3;
    }

    &.mdc-text-field--focused {
        background-color: $slateblue-off-high-opacity !important;
    }

    &.mdc-text-field--filled .mdc-text-field__ripple:after,
    &.mdc-text-field--filled .mdc-text-field__ripple:before {
        background-color: $transparent;
    }
}

.select-background-outlined:hover {
    @include select.container-fill-color(rgba(76, 96, 133, .3));
}

.mdc-select--disabled {
    border-radius: 4px;
}

.mdc-list-item__text,
.mdc-select__selected-text {
    font-size: 14px;
    letter-spacing: 0.68px;
}


.page-host-content:not(:has(#swap)) .select-mdc-background-filled {
        --mdc-theme-surface: #{$color-accent-700};
        --mdc-theme-on-surface: $white;
        --mdc-theme-primary: $purple-navy;
        @include select.container-fill-color(('default': $color-accent-700, 'disabled': rgba(0, 0, 0, 0.1)));
        @include select.label-color(('default': $color-grey-500, 'hover': $color-grey-500, 'focus': $color-grey-500, 'disabled': $color-grey-700));
        @include select.ink-color(('default': $color-grey-100, 'disabled': $color-grey-600));
        @include select.dropdown-icon-color(('default': $white, 'hover': $white, 'focus': $white, 'disabled': $white));
        @include select.bottom-line-color(('default': $transparent, 'hover': $transparent, 'focus': $transparent, 'disabled': $transparent));
        font-size: $regular;
    
        &.mdc-select--filled .mdc-menu-surface--is-open-below {
            padding: 0px;
            border-radius: 4px;
            padding: 16px 0;
            border: 1px solid var(----cds-menu--container-line, #404A65);
            background: var(----cds-menu--container-color, #323648);
           
            .mdc-list-item:hover {
                opacity: 1;
                background-color: $color-accent-600;
                
            }
        }
    
        &:hover {
            @include select.container-fill-color($color-accent-600);
        }
    
        &.select-mdc-background-filled.mdc-select--activated .mdc-select__anchor {
            background-color: $color-accent-500 !important;
        }
    
        .mdc-select--disabled {
            background-color: $slateblue-off-low-opacity;
            @include select.ink-color((disabled: $white));
        }
    
        .mdc-select__anchor .mdc-select__ripple:before {
            background-color: unset;
        }
    
        .mdc-ripple-surface:hover::before,
        .mdc-ripple-surface:hover .mdc-list-item__ripple::before,
        .mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
            opacity: 0 !important;
        }
    
        .mdc-menu-surface::-webkit-scrollbar-thumb {
            background-color: $blue;
        }
    
        @include textfield.fill-color($color-accent-700);
        @include textfield.ink-color($color-grey-100);
        @include textfield.outline-color($transparent);
        @include textfield.focused-outline-color($transparent);
        @include textfield.hover-outline-color($transparent);
        @include textfield.label-color($color-grey-500);
        @include textfield.disabled-ink-color($white);
        @include textfield.disabled-fill-color($primaryborder);
        @include textfield.disabled-label-color($white);
        @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
        @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
        @include textfield.disabled-outline-color($transparent);
        @include textfield.disabled-bottom-line-color($transparent);
        @include textfield.disabled-placeholder-color($mediumgray);
        @include textfield.bottom-line-color($transparent);
        @include textfield.line-ripple-color($transparent);
        @include textfield.hover-bottom-line-color($transparent);
        @include textfield.placeholder-color($mediumgray);
        @include textfield.prefix-color($color-grey-100);
        @include textfield.suffix-color($color-grey-100);
        @include textfield.caret-color(rgba(255, 255, 255, 0.3));
        border: 0.5px;
        border-radius: 4px;
    
        .mdc-ripple-surface:hover::before,
        .mdc-ripple-surface:hover .mdc-list-item__ripple::before,
        .mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
            background: rgb(70, 87, 120, .4);
            opacity: 1;
        }
    
        &.mdc-text-field--disabled {
            opacity: .3;
        }
    
        &.mdc-text-field--focused {
            background-color: $color-accent-500 !important;
        }
    
        &.mdc-text-field--filled .mdc-text-field__ripple:after,
        &.mdc-text-field--filled .mdc-text-field__ripple:before {
            background-color: $transparent;
        }
    }


.select-background-filled {
    --mdc-theme-surface: rgb(48, 49, 65);
    --mdc-theme-on-surface: $white;
    --mdc-theme-primary: $purple-navy;
    @include select.container-fill-color(('default': $slateblue-off-low-opacity, 'disabled': rgba(0, 0, 0, 0.1)));
    @include select.label-color(('default': $gray, 'hover': $gray, 'focus': $gray, 'disabled': $gray));
    @include select.ink-color(('default': $white, 'disabled': $white));
    @include select.dropdown-icon-color(('default': $white, 'hover': $white, 'focus': $white, 'disabled': $white));
    @include select.bottom-line-color(('default': $transparent, 'hover': $transparent, 'focus': $transparent, 'disabled': $transparent));
    font-size: $regular;

    &.mdc-select--activated .mdc-select__anchor {
        background-color: $slateblue-off-high-opacity !important;
    }

    .mdc-select--disabled {
        background-color: $slateblue-off-low-opacity;
        @include select.ink-color((disabled: $white));
    }

    .page-host-content:has(#swap) &.mdc-text-field--focused {
        background-color: $slateblue-off-high-opacity !important;
    }

    .mdc-select__anchor .mdc-select__ripple:before {
        background-color: unset;
    }

    .mdc-ripple-surface:hover::before,
    .mdc-ripple-surface:hover .mdc-list-item__ripple::before,
    .mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
        opacity: 1;
    }

    .page-host-content:has(#swap) .mdc-ripple-surface:hover::before,
    .mdc-ripple-surface:hover .mdc-list-item__ripple::before,
    .mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
        background: $primarybackground !important;
    }

    .mdc-menu-surface::-webkit-scrollbar-thumb {
        background-color: $blue;
    }

    //Lookup element
    @include textfield.fill-color($slateblue-off-low-opacity);
    @include textfield.ink-color($white);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include icon.trailing-icon-color($white);
    @include textfield.label-color($gray);
    @include textfield.disabled-ink-color($white);
    @include textfield.disabled-fill-color($primaryborder);
    @include textfield.disabled-label-color($white);
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color($transparent);
    @include textfield.disabled-placeholder-color($mediumgray);
    @include textfield.bottom-line-color($transparent);
    @include textfield.line-ripple-color($transparent);
    @include textfield.hover-bottom-line-color($transparent);
    @include textfield.placeholder-color($mediumgray);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color(rgba(255, 255, 255, 0.3));
    border: 0.5px;
    border-radius: 4px;

    .mdc-ripple-surface:hover::before,
    .mdc-ripple-surface:hover .mdc-list-item__ripple::before,
    .mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
        // .page-host-content:has(#swap){
        //     background: rgb(70, 87, 120, .4);
        // }
        opacity: 1;
    }

    .page-host-content:has(#swap) .mdc-ripple-surface:hover::before,
    .mdc-ripple-surface:hover .mdc-list-item__ripple::before,
    .mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
        background: rgb(70, 87, 120, .4) !important;
    }

    &.mdc-text-field--disabled {
        opacity: .3;
    }
    .page-host-content:has(#swap) &.mdc-text-field--focused {
        background-color: $slateblue-off-high-opacity !important;
    }

    &.mdc-text-field--filled .mdc-text-field__ripple:after,
    &.mdc-text-field--filled .mdc-text-field__ripple:before {
        background-color: $transparent;
    }
}

.select-background-filled:hover {
    @include select.container-fill-color(rgba(76, 96, 133, .4));
    @include textfield.fill-color($slateblue-off-medium-opacity);
}

.select-background-filled-white {
    --mdc-theme-surface: rgb(48, 49, 65);
    --mdc-theme-on-surface: $white;
    --mdc-theme-primary: $purple-navy;
    @include select.container-fill-color(('default': $transparent, 'disabled': rgba(0, 0, 0, 0.1)));
    @include select.label-color(('default': $gray, 'hover': $gray, 'focus': $gray));
    @include select.ink-color($white);
    @include select.dropdown-icon-color(('default': $white, 'hover': $white, 'focus': $white));
    @include select.bottom-line-color(('default': $transparent, 'hover': $transparent, 'focus': $transparent, 'disabled': $transparent));
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: $regular;

    .mdc-ripple-surface:hover::before,
    .mdc-ripple-surface:hover .mdc-list-item__ripple::before {
        background: $blue;
        opacity: 0.1;
    }

    .mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
        background: $blue;
        opacity: 0.2;
    }

    .mdc-menu-surface::-webkit-scrollbar-thumb {
        background-color: $blue;
    }
}

.select-background-outlined-white {
    --mdc-theme-surface: rgb(48, 49, 65);
    --mdc-theme-on-surface: $white;
    @include select.container-fill-color($secondarybackground);
    @include select.label-color(('default': $gray, 'hover': $gray, 'focus': $gray));
    @include select.ink-color($white);
    @include select.dropdown-icon-color($white);
    @include select.outline-color(('default': rgba(255, 255, 255, 0.4), 'hover': $purple-navy, 'focus': $purple-navy));
}

.select-background-transparent {
    --mdc-theme-surface: rgb(84, 101, 133);
    --mdc-theme-on-surface: $white;
    @include select.container-fill-color(rgba(0, 0, 0, 0));
    @include select.label-color(('default': $gray, 'focus': $gray));
    @include select.ink-color($white);
    @include select.dropdown-icon-color(('default': $white, 'hover': $white, 'focus': $white));
    @include select.outline-color(('default': $purple-navy, 'hover': rgba(76, 96, 133, 0.7), 'focus': rgba(76, 96, 133, 0.7)));

    ::-webkit-scrollbar-thumb {
        background-color: $gray;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(168, 187, 191, 1);
    }
}

.select-label-style {
    --mdc-theme-surface: $offblack;
    --mdc-theme-on-surface: $white;
    $transparent: rgba(0, 0, 0, 0);
    @include select.dropdown-icon-color(('default': $white, 'hover': $white, 'focus': $white, 'disabled': rgba(255, 255, 255, 0.25)));
    @include select.ink-color(('default': $white, 'hover': $white, 'focus': $white, 'disabled': rgba(255, 255, 255, 0.25)));
    @include select.container-fill-color($transparent);
    @include select.label-color(('default': $white, 'focus': $white, 'disabled': rgba(255, 255, 255, 0.25)));
    @include select.label-floating-color(('default': $transparent, 'focus': $transparent));
    @include select.outline-color(('default': $transparent, 'hover': $transparent, 'focus': $transparent));
    @include select.outlined-height(29px);

    .mdc-menu-surface {
        border: 0;

        mdc-list mdc-list-item .mdc-list-item__text {
            color: $white !important;
        }
    }

    .mdc-select__anchor {
        padding-left: 0 !important;
    }

    .mdc-select__selected-text {
        opacity: .75;
    }

    mdc-notched-outline {
        .mdc-notched-outline__leading {
            display: none;
        }

        .mdc-notched-outline__notch > mdc-floating-label {
            left: 0;
        }
    }

    .mdc-ripple-surface:hover::before,
    .mdc-ripple-surface:hover .mdc-list-item__ripple::before {
        background: $blue;
        opacity: 0.1;
    }

    .mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
        background: $blue;
        opacity: 0.2;
    }
}

.radio {
    @include radio.ink-color($transparent);
    @include radio.unchecked-stroke-color($transparent);
    @include radio.checked-stroke-color($transparent);

    .mdc-radio__background {
        background-color: rgba(76, 96, 133, .2);
        border-radius: 100%;
    }

    &:hover .mdc-radio__background {
        background-color: rgba(76, 96, 133, .4);
    }

    .mdc-radio__inner-circle,
    .mdc-radio__outer-circle {
        border-radius: 100%;
    }

    .mdc-radio__native-control:checked+.mdc-radio__background {
        background-color: $darkergreen;
    }

    .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
        border-width: unset;
        background-color: rgba(57, 226, 157, 0.85);
    }

    .mdc-radio__ripple:after,
    .mdc-radio__ripple:before {
        background-color: unset;
    }
}

*[mdc-tooltip] {
    @media (width <= $desktop) {
        @include disable-select();
    }
}

.slider-default {
    @include slider.track-active-color($blue);
    @include slider.thumb-color($blue);
    @include slider.thumb-ripple-color($blue);
    margin: 0 15px;

    .mdc-slider__thumb-knob {
        border: 7px solid $blue;
        height: 14px;
        width: 14px;
    }
}

.mdc-tooltip.mdc-tooltip--shown::after {
    opacity: 1;
}

.mdc-tooltip.mdc-tooltip--hide {
    display: none;
}

.mdc-tooltip {
    @include tooltip.fill-color($primaryborder);
    @include tooltip.label-ink-color($white);
    border-radius: 4px;
    margin-top: 5px;
    box-shadow: unset;
    max-width: 250px;
    z-index: 2040;

    .mdc-tooltip__surface {
        min-height: 32px;
        font-size: 12px;
        font-weight: 300;
        padding: 10px;
        transform: scale(1) !important;
    }

    .mdc-tooltip__surface:not(.tooltip-wo-truncate) {
        @include truncate-text(5);

        &::after {
            content: "";
            height: 8px;
            width: 100%;
            bottom: 0;
            left: 0;
            position: absolute;
            display: block;
            background: $primaryborder;
            z-index: 2040;
        }
    }

    &--vip-cashback {
        max-width: 210px;
        width: 100%;

        .mdc-tooltip__surface {
            max-width: 330px;
            width: 330px;
            line-height: 20px;

            @media (min-width: $desktop) {
                position: absolute;
                top: -80px;
                right: 117px;
            }

            @media (max-width: $tablet) and (min-width: $phone) {
                position: absolute;
                top: -60px;
                left: 20px;
            }

            @media (max-width: $phone) {
                max-width: 100%;
                width: 100%;
            }
        }

        @media (max-width: $tablet) and (min-width: $phone) {
            max-width: 330px;
        }

        @media (max-width: $phone) {
            max-width: 100%;
            left: 5px !important;
        }
    }

    @media (max-width: $desktop) {
        display: none !important;
    }
}

.mdc-tooltip::after {
    border-color: $transparent $transparent $primaryborder;
    border-style: solid;
    border-width: 5px;
    bottom: 100%;
    content: ' ';
    left: 50%;
    margin-left: -5px;
    position: absolute;
    opacity: 0;
    transition: opacity 75ms cubic-bezier(.4, 0, 1, 1) 0ms;
}

.arrow-position-below::after {
    top: 100% !important;
    transform: rotate(180deg) !important;
}

.mdc-select,
.mdc-text-field {
    .mdc-floating-label {
        font-size: 14px !important;
    
        @media (max-width: $desktop) {
            font-size: 12px !important;
        }

        &--float-above {
            transform: translateY(-106%) scale(.8571) !important;
        }
    }

    &.no-float-label {
        .mdc-floating-label {
            &--float-above {
                top: 50%;
                transform: translateY(-50%) !important;
            }
        }
    }
}

.linear-progress-file {
    @include linear-progress.bar-color($green);
}

.mdc-dialog {
    @include dialog.container-fill-color($secondarybackground);
    @include dialog.title-ink-color($white);
    @include dialog.content-ink-color($white);
    @include dialog.shape-radius(4, false);

    .mdc-dialog__title {
        font-size: $extralarge;
        padding: 0 18px 9px;
        text-align: left;
    }

    .mdc-dialog__content {
        text-align: left;
    }

    .mdc-button .mdc-button__ripple:before,
    .mdc-button .mdc-button__ripple:after {
        background-color: unset;
    }
}

.mdc-drawer {
    border-right: unset;
}

.input-blue-filled {
    @include textfield.fill-color(rgba(76, 96, 133, 1));
    @include textfield.ink-color($white);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include icon.trailing-icon-color($white);
    @include textfield.label-color($white);
    @include textfield.disabled-ink-color($white);
    @include textfield.disabled-fill-color($primaryborder);
    @include textfield.disabled-label-color($white);
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color($transparent);
    @include textfield.disabled-placeholder-color($mediumgray);
    @include textfield.bottom-line-color($transparent);
    @include textfield.line-ripple-color($transparent);
    @include textfield.hover-bottom-line-color($transparent);
    @include textfield.placeholder-color($mediumgray);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color(rgba(255, 255, 255, 0.3));
    border: 0.5px;
    border-radius: 4px;

    .mdc-menu {
        background-color: $primaryborder;
        color: $white;
    }

    &:hover {
        @include textfield.fill-color(rgb(70, 87, 120, 1));
    }

    &.mdc-text-field--focused {
        @include textfield.fill-color($slateblue-off-high-opacity);
    }

    .mdc-ripple-surface:hover::before,
    .mdc-ripple-surface:hover .mdc-list-item__ripple::before,
    .mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
        background: rgb(70, 87, 120, .4);
        opacity: 1;
    }

    &.mdc-text-field--disabled {
        opacity: .3;
    }
}

.page-host-content:not(:has(#swap)) .input-mdc-filled {
    @include textfield.fill-color($color-accent-700);
    @include textfield.ink-color($color-grey-100);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include icon.trailing-icon-color($white);
    @include textfield.label-color($color-grey-500);
    @include textfield.disabled-ink-color($white);
    @include textfield.disabled-fill-color($primaryborder);
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color($transparent);
    @include textfield.disabled-placeholder-color($mediumgray);
    @include textfield.bottom-line-color($transparent);
    @include textfield.line-ripple-color($transparent);
    @include textfield.hover-bottom-line-color($transparent);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color(rgba(255, 255, 255, 0.3));
    border: 0.5px;
    border-radius: 4px;

    &.input-mdc-filled:not(.mdc-text-field--disabled) .mdc-floating-label {
        font-size: $regular;
        color: $color-grey-500;
        line-height: 16px;
        letter-spacing: .24px;
    }

    .mdc-menu {
        background-color: $primaryborder;
        color: $white;
    }

    .mdc-text-field__input::placeholder {
        font-size: $regular;
    }

    &:hover {
        @include textfield.fill-color($color-accent-600);
    }

    &.mdc-text-field--focused {
        @include textfield.fill-color($color-accent-500);
    }

    .mdc-ripple-surface:hover::before,
    .mdc-ripple-surface:hover .mdc-list-item__ripple::before,
    .mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
        background: rgb(70, 87, 120, .4);
        opacity: 1;
    }

    &.mdc-text-field--disabled {
        opacity: .3;
    }
}
