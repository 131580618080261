@import 'variables';

.fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.saving-icon {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
    margin-top: -3px;
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.type-writer {
    animation: typewriterAnimation .10s steps(20) .1s 1 normal both;
}

@keyframes typewriterAnimation {
    from {
        width: 0;
    }

    to {
        width: 20.6%;
    }
}

.fade-in {
    animation: opacityAnimation .1s;
}

@keyframes opacityAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.skeleton {
    background-color: $slateblue-off-medium-opacity;
    opacity: .7;
    border-radius: 4px;
    animation: skeletonLoading .5s linear infinite alternate;
}

@keyframes skeletonLoading {
    0% {
        background-color: rgba(56, 59, 82, 1);
    }

    50% {
        background-color: rgba(57, 65, 86, 1);
    }

    100% {
        background-color: rgba(64, 75, 100, 1);
    }
}

.translate-x-animation {
    animation: translateXAnimation linear forwards;
}

.translate-x-reverse-animation {
    animation: translateXAnimationReverse 0.5s linear forwards;
}

.horizontal-shake {
    animation: horizontal-shake .15s forwards;
}

@keyframes horizontal-shake {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}
