.policy {
    div>ol {
        counter-reset: list-counter;
    }

    strong {
        color: $green;
        font-size: $large;

        @media ($desktop <= width) {
            font-size: $extralarge;
        }
    }

    ol {
        list-style: none;
        margin-bottom: 0;

        padding-left: 0;

        // Section Headers
        li {
            font-size: $extralarge;
            letter-spacing: 1.12px;
            font-weight: bolder;
            padding: $extrahugeplus 0;
            border-top: none !important;

            &:before {
                content: counter(list-counter) ". ";
                counter-increment: list-counter;
                color: $green;
                font-size: $extralarge;
                letter-spacing: 1.38px;

                @media (max-width: $desktop) {
                    font-size: $large;
                }
            }

            // Section Paragraphs
            ol li {
                @media (max-width: 900px) {
                    padding: $largeplus 0 0 0;
                    word-break: break-word;
                }

                padding: $largeplus 0 0 2.6rem;
                font-family: $secondaryfont;
                font-size: $medium;
                letter-spacing: 0.98px;
                font-weight: normal;
                color: $gray;
                border-top: 0;

                &:not(:first-child) {
                    border: none;
                }

                &:last-child {
                    border: none;
                }

                &:before {
                    content: none;
                }

                // Subsections
                ol {
                    counter-reset: sublist-counter;
                    margin-left: $extrahugeplus;

                    li {
                        padding-left: 0;

                        &:before {
                            font-size: 18px;
                            counter-increment: sublist-counter;
                            content: counter(list-counter) "." counter(sublist-counter) " ";

                            @media (max-width: $desktop) {
                                font-size: $regular;
                            }
                        }
                    }
                }
            }
        }
    }

    .legal-page-content {
        margin: $extrahugeplus auto;

        @media ($desktop <= width) {
            margin: $extrahugeplus auto 120px;
        }

        @media ($tablet <= width) {
            margin: 0 auto $largePad;
        }


        p:has(strong),
        .text-title p {
            margin: $largeplus 0 !important;
        }

        p:not(.text-content):has(strong) strong,
        .text-title p {
            line-height: normal;
            font-size: $extralarge;
            letter-spacing: 0.768px;
            color: $text-title-legal-page;

            @media ($desktop <= width) {
                font-size: $extralarge;
            }
        }

        p,
        .text-content p,
        .text-content p:has(strong) strong {
            font-size: $medium;
            line-height: 32px;
            letter-spacing: 0.512px;

            @media ($desktop <= width) {
                font-size: $medium;
            }

            @media ($desktop > width) {
                font-size: $regular;
            }

            @media ($desktop > width) {
                font-size: $regular;
            }
        }

        .text-content p {
            margin: $largeplus auto !important;
        }

        .text-content:not(:last-child):not(.legal-page-introduction)::after {
            width: 100%;
            height: 1px;
            content: '';
            display: block;
            margin: $extrahugeplus auto;
            background-color: $line-color-after-content;
        }

        .last-update p {
            margin: 80px 0 20px 0;
            font-size: $medium;
            font-weight: 700;
            text-align: right;
            color: $text-title-legal-page;

            @media (width <= $tablet) {
                margin: 0 0 20px 0;
            }
        }

        ul, ol {
            margin-left: $largePad;
            padding: 0;
            line-height: 32px;
            margin-left: 40px;

            @media ($desktop <= width) {
                font-size: $medium;
            }

            li:not(:last-child) {
                margin-bottom: $largeplus;
            }

            li:first-child {
                margin-top: $largeplus;
            }

            li {
                font-size: $medium;
                font-weight: 400;
                color: $middlegray;
                padding-top: 0;
                padding-bottom: 0;

                &:before {
                    content: counter(list-counter) ". ";
                    counter-increment: list-counter;
                    color: $green;
                    font-size: $medium;
                    letter-spacing: 1.38px;

                    @media (max-width: $desktop) {
                        font-size: $large;
                    }
                }

                @media (max-width: $tablet) {
                    font-size: $regular;
                }

                ol {
                    counter-reset: sublist-counter;
                    margin-left: 40px;

                    li {
                        padding-left: 0;
                        font-size: $medium;

                        @media (max-width: $tablet) {
                            font-size: $regular;
                        }

                        &:before {
                            content: counter(list-counter) "." counter(sublist-counter) " ";
                            counter-increment: sublist-counter;
                            font-family: $primaryfont;
                            color: $green;
                            font-size: $medium;
                            letter-spacing: 1.38px;

                            @media (max-width: $desktop) {
                                font-size: $large;
                            }
                        }
                    }
                }
            }
        }
    }
}
