$primaryfont: 'Roboto', sans-serif;
$secondaryfont: 'Playfair Display', serif;

$tiny: 7px;
$extra-tiny: 8px;
$extrasmall: 10px;
$small: 12px;
$smallplus: 13px;
$regular: 14px;
$regularplus: 15px;
$medium: 16px;
$mediumplus: 17px;
$large: 18px;
$largeplus: 20px;
$largeplusplus: 22px;
$extralarge: 24px;
$extralargeplus: 25px;
$extralargeMax: 28px;
$big: 30px;
$huge: 32px;
$big: 30px;
$hugeplus: 34px;
$extrahuge: 38px;
$extrahugeplus: 40px;
$extrahugeplusplus: 42px;
$extraextrahuge: 50px;
$giant: 100px;
$giantplus: 120px;
$tinyPad: 5px;
$extragiant: 150px;
$thinborder: 4px;
$tinyPad: 5px;
$smallPad: 10px;
$smallPadPlus: 15px;
$regularPad: 20px;
$mediumPad: 30px;
$bigPad: 40px;
$extrabigpad: 50px;
$largePad: 60px;
$hugePad: 80px;

$progressgray: rgba(214, 214, 214, 1);
$cg-purple: rgba(67, 0, 169, 1);
$cg-primary-hover: rgba(35, 11, 117, 1);
$cg-blue: rgba(0, 51, 140, 1);
$cg-blue-hover: rgba(74, 83, 181, 1);
$secondarycolor: rgba(239, 239, 253, 1);
$secondarycolor-hover: rgba(216, 216, 255, 1);
$select-gray: rgba(242, 242, 242, 1);
$secondarycolor-text: rgba(12, 42, 146, 1);
$red-destructive: rgba(255, 0, 0, 0.1);
$red-destructive-pressed: rgba(255, 0, 0, 0.3);
$red-text: rgba(255, 0, 0, 0.75);
$offblack: rgba(55, 56, 75, 1);
$offblack-hover: rgba(76, 96, 133, 0.45);
$offblack-hover-clearer: rgba(76, 96, 133, 0.25);
$offblack-hover-transparent: rgba(76, 96, 133, 0);
$faq-text-color: rgba(152, 152, 160, 1);
$faq-card-bg-hover: linear-gradient(0deg, #303141 0%, #303141 100%), #303141;
$faq-card-bg-selected: linear-gradient(0deg, #414E6A 0%, #414E6A 100%), #303141;
$purple-disabled: rgba(49, 15, 162, 0.2);
$purplehover: rgba(49, 15, 162, 0.5);
$divisor-gray: rgba(196, 196, 196, 0.6);
$cg-purple: rgba(67, 0, 169, 1);
$black: rgba(0, 0, 0, 1);
$black-low-opacity: rgba(0, 0, 0, .15);
$divisor-gray: rgba(196, 196, 196, 0.6);
$divisor-gray-light: rgba(196, 196, 196, 0.3);
$midgray: rgba(112, 112, 112, 1);
$cg-blue: rgba(0, 51, 140, 1);
$yellow: rgba(254, 215, 102, 1);
$crayola: rgba(254, 215, 102, 0.2);
$mustard: rgba(226, 160, 21, 0.3);
$metallic-sunburst: rgb(158, 131, 52);
$salem: rgb(21, 132, 87);
$slategrey: rgba(84, 101, 133, 1);
$slateblue: rgba(132, 155, 198, 1);
$blue: rgba(87, 98, 213, 1);
$hoverblue: rgba(74, 83, 181, 1);
$darkblue: rgba(34, 36, 63, 1);
$green: rgba(57, 226, 157, 1);
$darkgreen: rgba(57, 226, 157, 0.2);
$darkergreen: rgba(57, 226, 157, 0.05);
$red: rgba(253, 74, 74, 1);
$off-red: rgba(255, 0, 0, 0.2);
$light-blue: rgba(0, 143, 174, 1);
$off-light-blue: rgba(0, 143, 174, 0.3);
$white: rgba(255, 255, 255, 1);
$shadow: rgba(44, 45, 51, 0.8);
$lightergray: rgba(250, 250, 255, 1);
$middlegray: rgba(250, 250, 255, 0.75);
$lightgray70: rgba(182, 182, 182, 0.714);
$color-grey-500: rgba(182, 182, 182, 1);
$color-grey-600: rgba(133, 133, 133, 1);
$inputgray: rgba(249, 249, 249, 1);
$input-placeholder: rgba(0, 0, 0, 0.3);
$gray: rgba(206, 206, 206, 1);
$slategray: rgba(86, 89, 116, 1);
$mediumgray: rgba(255, 255, 255, 0.5);
$darkgray: rgba(255, 255, 255, 0.3);
$smalldarkgray: rgba(255, 255, 255, 0.4);
$cds-primary-blue: rgba(87, 98, 213, 1);
$darkergray: rgba(255, 255, 255, 0.02);
$darkergray2: rgba(255, 255, 255, 0.1);
$darkergray3: rgba(255, 255, 255, 0.25);
$slateblue-off-lowest-opacity: rgba(76, 96, 133, .1);
$slateblue-off-lower-opacity: rgba(76, 96, 133, .15);
$slateblue-off-low-opacity: rgba(76, 96, 133, .2);
$slateblue-off-medium-opacity: rgba(76, 96, 133, .4);
$slateblue-off-high-opacity: rgba(76, 96, 133, .6);
$offblack-hover-clearer: rgba(76, 96, 133, 0.2);
$offblack-hover-transparent: rgba(76, 96, 133, 0);
$stateblue-off-medium-non-opacity: rgba(70,87,120,1);
$transparent: rgba(0, 0, 0, 0);
$softblack: rgba(70, 70, 70, 1);
$mediumblack: rgba(51, 51, 51, 1);
$tag-green: rgba(90, 213, 87, 0.3);
$off-green: rgba(0, 174, 103, 0.1);
$success-green: rgba(0, 174, 103, 1);
$placeholder-success-green: rgba(57, 226, 157, 0.03);
$placeholder-error-red: rgba(253, 74, 74, 0.03);
$tag-yellow: rgba(254, 215, 102, 1);
$faded-yellow: rgba(212, 215, 80, 0.35);
$brown: rgba(162, 39, 0, 1);
$lighterbrown: rgba(137, 98, 15, 1);
$off-brown: rgba(162, 39, 0, 0.35);
$purple-text: rgba(49, 15, 162, 1);
$off-purple-text: rgba(46, 0, 144, 0.3);
$gray-icon-header: rgba(152, 163, 182, 1);
$purple-navy: rgb(76, 96, 133);
$charcoal: rgb(60, 67, 93);
$soft-white: rgba(255, 255, 255, 0.05);
$palegray: rgba(243, 245, 247, 1);
$backgroundcontrol: rgba(249, 249, 249, 1);
$darkblack: rgba(43, 44, 59, 1);
$vistablue: rgba(116, 150, 212, 1);
$independence-blue: rgba(70, 82, 112, 1);
$formalgrey: rgba(150, 151, 156, 1);
$police-blue: rgba(64, 78, 105, 1);
$dropdownprimarybackground: rgb(43, 44, 60);
$backgroundburger: rgba(50, 54, 72, 1);
$backgroundexpandable: rgba(76, 96, 133, 0.20);
$dropdownsecondarybackground: rgb(60, 61, 77);
$dropdownhighlight: $yellow;
$dropdownunhighlight: rgb(150, 151, 156);
$cg-purple-gradient: linear-gradient(to right,
        $cg-purple 2.45%,
        $cg-blue 101.9%);
$cg-purple-light-gradient: linear-gradient(180deg, 
        rgba(55, 56, 75, 0.20) 0%,
        #37384B 100%);
$crayola: rgba(50, 52, 69, 1);
$primarybackground: $offblack;
$secondarybackground: rgba(48, 49, 65, 1);
$tertiarybackground: rgba(60, 61, 77, 1);
$hoverbackground: rgba(59, 67, 92, 1);
$darkslategray: rgb(50, 54, 72, 1);
$lightgray: rgba(206, 207, 213, 1);
$cg-purple-background: rgba(87, 98, 213, .45);
$cg-purble-background-dark: rgba(87, 98, 213, 0.05);
$tc-green: rgba(164,255,164,1);
$charcoal-darker: rgba(59, 64, 87);
$charcoal-black: rgba(38, 40, 51, 1);
$charcoallight: rgba(84, 85, 112, 1);
$thumbnail-default: rgba(55, 62, 85, 1);
$thumbnail-hover: rgba(59, 69, 95, 1);
$lightpurple: rgba(113, 123, 230, 1);

$primarycolor: rgba($white, 1);
$primaryborder: rgba(76, 96, 133, 1);
$crayola: rgba(50, 54, 72, 1);
$white-off-lowest-opacity: rgba(250, 252, 255, 0.02);
$link-color: rgba(149, 149, 157, 1);
$info-card-bg: rgb(70, 71, 94, 1);
$colorAdditional: rgba(114, 118, 125, 1);
$line-color-after-content: rgba(64, 74, 101, 1);
$text-title-legal-page: rgba(57, 226, 157, 1);
$primaryBorderAdditional: rgba(65, 78, 106, 1);
$color-grey-1000: rgba(31, 31, 31, 1);
$color-grey-700: rgba(107, 107, 107, 1);
$color-grey-600: rgba(133, 133, 133, 1);
$color-grey-500: rgba(182, 182, 182, 1);
$color-grey-300: rgb(240, 240, 240, 1);
$color-grey-200: rgba(247, 247, 247, 1);
$color-grey-100: rgba(252, 252, 252, 1);
$color-accent-500: rgba(64, 74, 101, 1);
$color-accent-600: rgba(60, 66, 89, 1);
$color-accent-700: rgba(50, 54, 72, 1);
$primary-300: rgba(221, 221, 255, 1);
$primary-200: rgba(239, 239, 253, 1);
$primary-100: rgba(233, 239, 252, 1);
$primary-700: rgba(87, 98, 213, 1);
$secondary-200: rgba(253, 226, 213, 1);
$secondary-900: rgba(48, 49, 65, 1);
$secondary-100: rgba(254, 240, 234, 1);
$secondary-600: rgba(245, 108, 45, 1);
$focused-line-color: rgba(101, 116, 145, 1);

$notification-green: rgba(76, 233, 165, 1);
$notification-red: rgba(251, 74, 74, 1);

$textfield-color-success: rgba(44, 79, 77, 1);
$textfield-color-hover-success: rgba(57, 100, 97, 1);
$textfield-color-error: rgba(84, 49, 61, 1);
$textfield-color-hover-error: rgba(101, 63, 76, 1);

$cg-border: 1px solid $primaryborder;
$link-color: rgba(149, 149, 157, 1);
$info-card-bg: rgb(70, 71, 94, 1);
$filter-color: brightness(0) saturate(100%) invert(64%) sepia(5%) saturate(327%) hue-rotate(202deg) brightness(92%) contrast(91%);

$smallphone: 320px;
$smallphoneplus: 375px;
$smallphonegrid: 376px;
$mediumphone: 425px;
$phone: 576px;
$smalltablet: 720px;
$tablet: 768px;
$desktop: 992px;
$laptop: 1024px;
$mediumdesktop: 1080px;
$widescreen: 1200px;
$widescreenplus: 1235px;
$mediumwidescreen: 1400px;
$laptoplarge: 1440px;
$extrascreen: 1500px;
$extrawidescreen: 1550px;
$extralargeplusscreen: 1920px;
$extralargescreen: 3839px;

$list-view-gradient: linear-gradient(to top, transparent, black 6%, black 98%, transparent);
$list-view-gradient-no-top: linear-gradient(to top, transparent, black 6%, black 100%, transparent);
$list-view-gradient-no-bottom: linear-gradient(to top, transparent, black 0%, black 98%, transparent);

$created-blue: rgb(34, 165, 239);
$pending-purple: rgb(179, 0, 208);
$rejected-red: rgb(255, 58, 58);
$disputed-orange: rgb(241, 79, 43);
$refunded-blue: rgb(133, 166, 253);
$partial-refund-yellow: rgb(237, 194, 112);
$partially-refunded-orange: rgb(226, 138, 57);
$refund-requested-brown: rgb(166, 100, 23);
$in-progress-green: rgb(182, 198, 86);
$partially-f-green: rgb(115, 198, 86);
$mark-as-sent-blue: rgb(86, 6, 255);
$cg-signin-blue: rgba(30, 31, 50, 1);
$cg-signin-button-default: rgba(57,226,157, 1);
$cg-signin-button-hover: rgba(97,232,177, 1);
$cg-signin-button-active: rgba(136,238,196, 1);

$regular-border: 1px solid $color-accent-500;

$grid-width-desktop: 1560px;
$default-grid-spacing: 16px;

$card-desktop-width: 284px;
$card-desktop-height: 432px;

$card-tablet-width: 236px;
$card-tablet-height: 344px;

$card-mobile-width: 160px;
$card-mobile-height: 292px;

$regular-border: 1px solid $color-accent-600;
$containers: ".container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl";
$grid-width-desktop: 1560px;
$default-grid-spacing: 16px;

@mixin background-properties() {
    background: transparent linear-gradient(90deg, rgba(17, 22, 52, 1) 0%, rgba(18, 28, 74, 1) 49%, rgba(17, 22, 52, 1) 100%) 0 0 no-repeat padding-box;
    /* w3c */
}
