.text-extra-small {
    font-size: $extrasmall;
    letter-spacing: 0.52px;
}

.text-small {
    font-size: $small;
    letter-spacing: 0.56px;
}

.text-small-static {
    font-size: $small;
    letter-spacing: 0.56px;
}

.text-regular-static {
    font-size: $regular;
}

.text-small-plus {
    font-size: $smallplus;
    letter-spacing: 0.62px;
}

.text-regular {
    font-size: $regular;
    letter-spacing: 0.68px;
}

.text-normal {
    font-size: $regular;
}

.text-regular-plus {
    font-size: $regularplus;
    letter-spacing: 0.76px;
}

.text-medium {
    font-size: $medium;
    letter-spacing: 0.51px;
}

.text-medium-static {
    font-size: $medium;
    letter-spacing: 0.51px;
}

.text-medium-plus {
    font-size: $mediumplus;
    letter-spacing: 0.51px;
}

.text-large {
    font-size: $large;
    letter-spacing: 1.12px;
}

.text-large-static {
    font-size: $large;
}

.text-big {
    font-size: $big;
}

.text-large-plus {
    font-size: $largeplus;
    letter-spacing: 1.20px;
}

.text-large-plus-static {
    font-size: $largeplus;
    letter-spacing: 1.20px;
}

.text-extra-large {
    font-size: $extralarge;
    letter-spacing: 0.76px;
}

.text-extra-large-static {
    font-size: $extralarge;
    letter-spacing: 0.76px;
}

.text-extra-large-plus {
    font-size: $extralargeplus;
    letter-spacing: 1.34px;
}

.text-extra-large-max {
    font-size: $extralargeMax;
    letter-spacing: 1.34px;
}

.text-huge {
    font-size: $huge;
    letter-spacing: 1.42px;
}

.text-extra-huge {
    font-size: $extrahuge;
    letter-spacing: 1.58px;
}

.text-extra-huge-plus {
    font-size: $extrahugeplus;
    letter-spacing: 1.28px;
}

.text-extra-extra-huge {
    font-size: $extraextrahuge;
    letter-spacing: 1.6px;
}

.text-giant {
    font-size: $giant;
    letter-spacing: 1.85px;
}

.text-extra-giant {
    font-size: $extragiant;
    letter-spacing: 2.15px;
}

.text-extra-small-o {
    font-size: $small;
    letter-spacing: 0.52px;
    opacity: 0.75;
    font-weight: 400;
}

.text-regular-o {
    font-size: $regular;
    letter-spacing: 0.68px;
    opacity: 0.75;
}

.text-medium-title {
    font-size: $medium;
    letter-spacing: 0.25px;
    font-weight: 500;
}

:not(.no-font-bp) {
    @media (max-width: $desktop) {
        &.text-small {
            font-size: $extrasmall !important;
        }

        &.text-small-plus {
            font-size: $smallplus !important;
        }

        &.text-regular {
            font-size: $small !important;
        }

        &.text-regular-plus {
            font-size: $regular !important;
        }

        &.text-medium {
            font-size: $regular !important;
        }

        &.text-medium-plus {
            font-size: $regular !important;
        }

        &.text-large {
            font-size: $medium !important;
        }

        &.text-large-plus {
            font-size: $large !important;
        }

        &.text-extra-large {
            font-size: $large !important;
        }

        &.text-extra-large-plus {
            font-size: $large !important;
        }

        &.text-huge {
            font-size: $extralarge !important;
        }

        &.text-extra-huge {
            font-size: $huge;
        }

        &.text-extra-extra-huge {
            font-size: $huge;
        }

        &.text-extra-huge-plus {
            font-size: 24px;
        }
    }

    @media (max-width: $phone) {
        &.title-size-plus {
            font-size: $largeplus;
        }

        &.text-normal {
            font-size: $small;
        }
    }
}

.font-bold {
    font-weight: bold;
}

.font-italic {
    font-style: italic;
}

.font-light {
    font-weight: 300;
}

.font-regular {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-large {
    font-weight: 600;
}

.text-green {
    color: $green !important;
}

.text-blue {
    color: $blue !important;
}

.text-color-grey-100 {
    color: $color-grey-100 !important;
}
.text-color-grey-500 {
    color: $color-grey-500 !important;
}

.text-red {
    color: $red !important;
}

.text-yellow {
    color: $yellow !important;
}

.text-white {
    color: $white !important;
}

.text-gray-100 {
    color: $color-grey-100 !important;
}

.text-gray-300 {
    color: $color-grey-300 !important;
}

.text-gray {
    color: $gray !important;
}

.text-gray-70 {
    color: $lightgray70;
}

.text-bright-white {
    color: var(--Color-Grey-100) !important;
}

.text-mediumgray {
    color: $mediumgray !important;
}

.text-middlegray {
    color: $middlegray !important;
}

.text-darkgray {
    color: $darkgray !important;
}

.text-smalldarkgray {
    color: $smalldarkgray !important;
}

.font-primary {
    font-family: $primaryfont !important;
}

.font-secondary {
    font-family: $secondaryfont;
}

.line-height-tiny {
    line-height: 1.0 !important;
}

.line-height-small {
    line-height: 1.2 !important;
}

.line-height-small-plus {
    line-height: 1.5 !important;
}

.line-height-medium {
    line-height: 2.2 !important;
}

.line-height-regular {
    line-height: 2.69 !important;
}

.line-height-large {
    line-height: 2.8 !important;
}

.no-decoration {
    text-decoration: none;
}

.letter-spacing-tiny {
    letter-spacing: .13px;
}

.letter-spacing-small {
    letter-spacing: 0.25px;
}

.order-created {
    color: $created-blue !important;
}

.order-pending {
    color: $pending-purple !important;
}

.order-rejected {
    color: $rejected-red !important;
}

.order-disputed {
    color: $disputed-orange !important;
}

.order-refunded {
    color: $refunded-blue !important;
}

.order-partial-refund {
    color: $partial-refund-yellow !important;
}

.order-partially-refunded {
    color: $partially-refunded-orange !important;
}

.order-refunded-requested {
    color: $refund-requested-brown !important;
}

.order-in-progress {
    color: $in-progress-green !important;
}

.order-partially-f {
    color: $partially-f-green !important;
}

.order-mark-as-sent {
    color: $mark-as-sent-blue !important;
}

