@use '@chicksgroup/styles/variables' as v;
@use 'styles/variables' as var;
@use '@chicksgroup/styles/colors' as c;
@use '@chicksgroup/styles/mixins' as m;
:root {
    // #region MARK: Chicksgold Theme
    --cds-primary-color: var(--cg-primary-700);
    --cds-primary-color-50: rgba(87, 98, 213, 0.502);
    --cds-success-color: var(--cg-success-700);
    --cds-error-color: var(--cg-error-500);
    --cds-warning-color: var(--cg-warning-700);
    --cds-tertiary-color: var(--cg-success-600);
    --cds-text-color: #{var.$white};
    --cds-secondary-text-color: #{var.$white};
    --cds-secondary-icon-button-color: var(--cg-secondary-900);
    --cds-text-field-input-overflow: ellipsis;
    --cds-title-color: var(--cds-text-color);
    --md-sys-color-on-surface-variant: rgba(72, 69, 78, 1);
    --md-sys-color-surface: var(--cds-secondary-icon-button-color);
    --tooltip-color: rgba(76, 96, 133, 1);
    // #endregion

    // #region MARK: Button
    --cds-button-height: 52px;
    --md-filled-button-container-shape: 4px;
    --md-filled-button-disabled-container-color: var(--cg-primary-900);
    --md-filled-button-disabled-label-text-color: var(--cds-text-color);
    --md-filled-button-label-text-color: var(--cg-grey-100);
    // #endregion

    // #region MARK: Text Field
    --cds-text-field-height: #{v.$largeplus};
    --cds-input-background: var(--cg-accent-700);
    --cds-input-hover-background-color: var(--cg-accent-600);
    --cds-input-focus-background-color: var(--cg-accent-500);
    --cds-background-success-color: var(--cg-success-1000);
    --cds-background-success-hover-color: var(--cg-success-900);
    --cds-background-success-hover-opacity: 1;
    --cds-background-success-focus-color: var(--cg-success-1000);
    --cds-background-error-color: var(--cg-error-1000);
    --cds-background-error-hover-color: var(--cg-error-900);
    --cds-background-error-focus-color: var(--cg-error-1000);

    --md-filled-text-field-top-space: #{v.$medium};
    --md-filled-text-field-bottom-space: #{v.$extra-tiny};
    --md-filled-text-field-with-label-top-space: #{v.$extra-tiny};
    --md-filled-text-field-with-label-bottom-space: #{v.$extra-tiny};
    --md-filled-text-field-with-leading-icon-leading-space: #{v.$medium};
    --md-filled-text-field-with-trailing-icon-trailing-space: #{v.$medium};
    --md-filled-text-field-icon-input-space: #{v.$extra-tiny};
    --md-filled-text-field-hover-state-layer-color: var(--cg-accent-600);
    --md-filled-text-field-hover-state-layer-opacity: 1;

    --cds-text-field-label-letter-spacing: 0.035px;
    --cds-text-field-floating-label-letter-spacing: 0.24px;
    --cds-text-field-input-letter-spacing: 0.035px;
    --cds-text-field-floating-label-color: var(--cg-grey-500);
    --cds-text-field-hover-floating-label-color: var(--cg-grey-500);
    --cds-text-field-focus-floating-label-color: var(--cg-grey-500);
    --md-filled-text-field-label-text-color: var(--cg-grey-500);
    --md-filled-text-field-label-text-size: #{v.$regular};
    --md-filled-text-field-label-text-weight: 400;
    --md-fileld-text-field-label-text-line-height: #{v.$medium};
    --md-filled-text-field-label-text-populated-line-height: #{v.$medium};
    --md-filled-text-field-label-text-populated-size: #{v.$small};
    --md-filled-text-field-hover-label-text-color: var(--cg-grey-500);
    --md-filled-text-field-focus-label-text-color: var(--cg-grey-500);

    --md-filled-text-field-input-text-prefix-color: var(--cg-grey-100);
    --md-filled-text-field-input-text-suffix-color: var(--cg-grey-100);

    --md-filled-text-field-input-text-color: var(--cg-grey-100);
    --md-filled-text-field-input-text-size: #{v.$regular};
    --md-filled-text-field-input-text-line-height: #{v.$medium};
    --md-filled-text-field-input-text-weight: 400;
    --md-filled-text-field-input-text-placeholder-color: var(--cg-grey-500);
    --md-filled-text-field-hover-input-text-color: var(--cg-grey-100);
    --md-filled-text-field-focus-input-text-color: var(--cg-grey-100);
    --md-filled-text-field-error-focus-input-text-color: var(--cg-grey-100);
    --md-filled-text-field-error-hover-input-text-color: var(--cg-grey-100);
    --md-filled-text-field-error-input-text-color: var(--cg-grey-100);

    --md-filled-text-field-caret-color: var(--cg-grey-100);
    --md-filled-text-field-focus-caret-color: var(--cg-grey-100);
    --md-filled-text-field-error-focus-caret-color: var(--cg-grey-100);

    --md-filled-text-field-disabled-container-color: var(--cg-accent-700);
    --md-filled-text-field-disabled-container-opacity: 1;
    --md-filled-text-field-disabled-label-text-color: var(--cg-grey-700);
    --md-filled-text-field-disabled-label-text-opacity: 1;
    --md-filled-text-field-disabled-input-text-color: var(--cg-grey-700);
    --md-filled-text-field-disabled-input-text-opacity: 1;
    --md-filled-text-field-disabled-leading-icon-color: var(--cg-grey-700);
    --md-filled-text-field-disabled-leading-icon-opacity: 1;
    --md-filled-text-field-disabled-trailing-icon-color: var(--cg-grey-700);
    --md-filled-text-field-disabled-trailing-icon-opacity: 1;

    --md-filled-text-field-error-input-text-color: var(--cg-grey-100);
    --md-filled-text-field-error-label-text-color: var(--cg-grey-500);
    --md-filled-text-field-error-leading-icon-color: var(--cg-grey-100);
    --md-filled-text-field-error-trailing-icon-color: var(--cg-grey-100);
    --md-filled-text-field-error-focus-input-text-color: var(--cg-grey-100);
    --md-filled-text-field-error-focus-label-text-color: var(--cg-grey-500);
    --md-filled-text-field-error-focus-leading-icon-color: var(--cg-grey-100);
    --md-filled-text-field-error-focus-trailing-icon-color: var(--cg-grey-100);
    --md-filled-text-field-error-hover-input-text-color: var(--cg-grey-100);
    --md-filled-text-field-error-hover-label-text-color: var(--cg-grey-500);
    --md-filled-text-field-error-hover-leading-icon-color: var(--cg-grey-100);
    --md-filled-text-field-error-hover-trailing-icon-color: var(--cg-grey-100);
    --md-filled-text-field-error-hover-state-layer-color: var(--cg-error-900);
    --md-filled-text-field-error-hover-state-layer-opacity: 1;
    
    --cds-text-field-leading-icon-size: #{v.$extralarge};
    --md-filled-text-field-leading-icon-size: #{v.$extralarge};
    --md-filled-text-field-leading-icon-color: var(--cg-grey-100);
    --md-filled-text-field-hover-leading-icon-color: var(--cg-grey-100);
    --md-filled-text-field-focus-leading-icon-color: var(--cg-grey-100);

    --cds-text-field-trailing-icon-size: 16px;
    --cds-text-field-loading-spinner-size: 20px;
    --md-filled-text-field-trailing-icon-size: #{v.$medium};
    --md-filled-text-field-trailing-icon-color: var(--cg-grey-100);
    --md-filled-text-field-hover-trailing-icon-color: var(--cg-grey-100);
    --md-filled-text-field-focus-trailing-icon-color: var(--cg-grey-100);
    // #endregion

    // #region MARK: Checkbox
    --cds-checkbox-container-color: var(--cg-accent-500);
    --md-checkbox-outline-color: var(--cg-accent-500);
    --md-checkbox-focus-outline-color: var(--cg-accent-300);
    --md-checkbox-hover-outline-color: var(--cg-accent-400);
    --md-checkbox-pressed-outline-color: var(--cg-accent-300);

    --md-checkbox-disabled-container-opacity: 1;
    --md-checkbox-disabled-outline-color: var(--cg-grey-800);

    --md-checkbox-selected-container-color: var(--cg-primary-700);
    --md-checkbox-selected-icon-color: var(--cg-grey-100);
    --md-checkbox-selected-focus-container-color: var(--cg-primary-500);
    --md-checkbox-selected-focus-icon-color: var(--cg-grey-100);
    --md-checkbox-selected-hover-container-color: var(--cg-primary-600);
    --md-checkbox-selected-hover-icon-color: var(--cg-grey-100);
    --md-checkbox-selected-pressed-container-color: var(--cg-primary-500);
    --md-checkbox-selected-pressed-icon-color: var(--cg-grey-100);

    --md-checkbox-selected-disabled-container-color: var(--cg-primary-900);
    --md-checkbox-selected-disabled-container-opacity: 1;
    --md-checkbox-selected-disabled-icon-color: var(--cg-primary-500);
    // #endregion

    // #region MARK: Option Box
    --cds-option-box-color: rgba(40, 45, 74, 1);
    --cds-option-box-hover-color: var(--cds-input-background-hover);
    // #endregion

    // #region MARK: Icon Button
    --md-filled-icon-button-container-secondary-color: var(--cds-input-background-hover);
    // #endregion

    // #region MARK: Menu
    --md-menu-container-shape: 4px;
    // #endregion

    // #region MARK: Autocomplete
    --cds-autocomplete-color: var(--cg-accent-700);
    --cds-autocomplete-border-width: 1px;
    --cds-autocomplete-border-color: var(--cg-accent-500);
    --cds-autocomplete-elevation: none;
    --cds-autocomplete-shadow-color: none;
    --cds-autocomplete-item-label-color: var(--cg-grey-100);
    --cds-autocomplete-item-selected-label-color: var(--cg-grey-100);
    --cds-autocomplete-item-hover-color: var(--cg-accent-400);
    --cds-autocomplete-item-pressed-color: var(--cg-accent-400);
    --cds-autocomplete-item-hover-opacity: 0.1;
    --cds-autocomplete-item-pressed-opacity: 0.1;
    --cds-autocomplete-item-selected-color: var(--cg-accent-500);
    --cds-autocomplete-item-ripple-blend-mode: screen;

    --cds-autocomplete-item-font-weight: 400;
    --cds-autocomplete-item-line-height: 16px;
    --cds-autocomplete-item-label-max-width: fit-content;
    --cds-autocomplete-item-font-size: #{v.$regular};
    --cds-autocomplete-item-letter-spacing: 0.035px;
    --cds-autocomplete-item-leading-gap: 8px;

    --cds-autocomplete-item-height: 52px;
    --md-menu-item-one-line-container-height: var(--cds-autocomplete-item-height);
    --md-menu-item-two-line-container-height: var(--cds-autocomplete-item-height);

    --cds-checkbox-container-size: 24px;
    --cds-checkbox-icon-size: var(--cds-checkbox-container-size);

    cds-autocomplete {
        --cds-text-field-trailing-icon-size: 24px;
    }
    // #endregion

    // #region MARK: Snackbar
    --cds-snackbar-closing-icon-size: 12px;
    // #endregion

    // #region MARK: Social Circles
    --cds-social-circles-background-color: var(--cg-primary-800);
    --cds-social-circles-background-color-hover: var(--cg-primary-800);
    --cds-social-circles-network-height: #{v.$huge};
    --cds-social-circles-network-width: #{v.$huge};

    cds-social-circles {
        --md-icon-size: #{v.$largeplus};
        --cds-icon-color: var(--cg-grey-100);
    }

    cds-banner {
        --cds-banner-warning-background-color: var(--cg-warning-1000);
        --cds-banner-error-background-color: var(--cg-error-1000);
        --cds-banner-default-background-color: var(--cg-accent-500);
        --cds-banner-text-color: var(--cg-grey-100);
        --cds-banner-icon-color: var(--cg-grey-100);
        --cds-banner-error-icon-color: var(--cg-grey-100);
        --cds-banner-warning-icon-color: var(--cg-grey-100);
        --cds-banner-height: 32px;
        --cds-banner-padding: 10px 52px;
        --cds-banner-font-weight: 300;
        --cds-banner-image-height: 20px;
        --cds-banner-image-width: 20px;
        --cds-banner-closing-icon-size: 16px;
    }
    // #endregion

    // #region MARK: Large Button
    --cds-large-button-arrow-size: 24px;

    cds-large-button {
        @include m.media(v.$desktop) {
            --cds-large-button-arrow-opacity: 1;
        }
    }
    // #endregion

    // #region MARK: Toast
    --cds-toast-width: 418px;
    --cds-toast-min-height: 104px;
    --cds-toast-body-min-height: 70px;
    --cds-toast-shape: #{v.$thinborder};
    --cds-toast-padding-block: #{v.$medium};
    --cds-toast-padding-inline: #{v.$largeplus};
    --cds-toast-title-gap: #{v.$small};
    --cds-toast-text-gap: #{v.$extra-tiny};
    --cds-toast-title-color: var(--cg-grey-100);
    --cds-toast-message-color: var(--cg-grey-100);
    --cds-toast-success-color: var(--cg-success-600);
    --cds-toast-error-color: var(--cg-error-600);
    --cds-toast-info-color: var(--cg-information-600);
    --cds-toast-warning-color: var(--cg-warning-600);
    // #endregion

    // #region MARK: Skeleton
    --cds-skeleton-shape: var(--cds-global-shape, 4px);
    --cds-shimmer-animation-start: #323648;
    --cds-shimmer-animation-end: #323648;
    --cds-skeleton-width: 100%;
    // #endregion

    // #region MARK: Number arrows
    --cds-number-arrows-icon-size: 16px;
    --cds-number-arrows-icon-color: var(--cg-grey-100);
    --cds-number-arrows-hover-icon-color: var(--cg-grey-500);
    --cds-number-arrows-pressed-icon-color: var(--cg-grey-600);
    --cds-number-arrows-focus-icon-color: var(--cds-number-arrows-icon-color, var(--cg-grey-100));
    --cds-number-arrows-align-items: center;
    --cds-number-arrows-justify-content: center;
    --cds-number-arrows-spacing: 4px;
    --cds-number-arrows-padding-inline: 4px;
    --cds-number-arrows-arrow-touch-area-height: 4px;
    --cds-number-arrows-arrow-touch-area-width: 8px;


    cds-number-field {
        --cds-icon-button-touch-size: max(20px, 100%);
    }
    // #endregion

    // #region MARK: No Results
    --cds-no-results-font-size: #{v.$regular};
    --cds-no-results-line-height: 16px;
    --cds-no-results-word-break: break-word;
    // #endregion

    // #region MARK: Tooltip
    --cds-tooltip-border-width: 0px;
    --cds-tooltip-text-line-height: 16px;
    --cds-tooltip-text-size: 12px;
    --cds-tooltip-elevation: 1050;
    --cds-tooltip-letter-spacing: 0.24px;
    --cds-tooltip-width: 320px;
    // #endregion
}
