@function percent-to-em($percent) {
    @return $percent / 100 * 1em;
}

$typography-scale: (
    'h1': (
        'phone': (
            'font-size': 24px,
            'weight': 700,
            'tracking': 0.01em,
            'line-height': 28px
        ),
        'tablet': (
            'font-size': 32px,
            'line-height': 36px
        ),
        'desktop': (
            'font-size': 64px,
            'tracking': 0.02em,
            'line-height': 76px
        )
    ),
    'h2': (
        'phone': (
            'font-size': 24px,
            'weight': 700,
            'tracking': 0.05em,
            'line-height': 28px
        ),
        'desktop': (
            'font-size': 40px,
            'tracking': 0.04em,
            'line-height': 48px
        )
    ),
    'h3': (
        'phone': (
            'font-size': 20px,
            'weight': 500,
            'tracking': 0.015em,
            'line-height': 24px
        )
    ),
    'h4': (
        'phone': (
            'font-size': 20px,
            'weight': 500,
            'tracking': 0.015em,
            'line-height': 24px
        ),
        'desktop': (
            'font-size': 32px,
            'line-height': 36px
        )
    ),
    'subtitle': (
        'phone': (
            'font-size': 16px,
            'weight': 700,
            'tracking': 0,
            'line-height': 24px
        )
    ),
    'body-big': (
        'phone': (
            'font-size': 14px,
            'weight': 400,
            'tracking': 0.001em,
            'line-height': 24px
        ),
        'desktop': (
            'font-size': 16px,
            'tracking': 0.015em,
            'line-height': 21px
        )
    ),
    'body-medium': (
        'phone': (
            'font-size': 14px,
            'weight': 500,
            'tracking': 0.02em,
            'line-height': 16px
        )
    ),
    'body-regular-wider': (
        'phone': (
            'font-size': 14px,
            'weight': 400,
            'tracking': 0.005em,
            'line-height': 20px
        )
    ),
    'body-regular': (
        'phone': (
            'font-size': 14px,
            'weight': 400,
            'tracking': 0.0025em,
            'line-height': 16px
        )
    ),
    'body-light': (
        'phone': (
            'font-size': 14px,
            'weight': 300,
            'tracking': 0,
            'line-height': 16px
        )
    ),
    'label-medium': (
        'phone': (
            'font-size': 12px,
            'weight': 500,
            'tracking': 0.02em,
            'line-height': 16px
        )
    ),
    'label-regular': (
        'phone': (
            'font-size': 12px,
            'weight': 400,
            'tracking': 0.02em,
            'line-height': 16px
        )
    ),
    'label-light': (
        'phone': (
            'font-size': 12px,
            'weight': 300,
            'tracking': 0.02em,
            'line-height': 16px
        )
    ),
    'caption-regular': (
        'phone': (
            'font-size': 10px,
            'weight': 400,
            'tracking': 0.03em,
            'line-height': 12px
        )
    ),
    'caption-light': (
        'phone': (
            'font-size': 10px,
            'weight': 300,
            'tracking': 0.02em,
            'line-height': 12px
        )
    ),
    'button': (
        'phone': (
            'font-size': 16px,
            'weight': 500,
            'tracking': 0.0025em,
            'line-height': 20px
        )
    ),
    'button-light': (
        'phone': (
            'font-size': 16px,
            'weight': 300,
            'tracking': 0.0025em,
            'line-height': 20px
        )
    ),
    'button-small': (
        'phone': (
            'font-size': 14px,
            'weight': 500,
            'tracking': 0,
            'line-height': 16px
        )
    )
);

// Function to get typography values
@function get-typography-value($element, $property, $breakpoint: 'phone') {
  @return map-get(map-get(map-get($typography-scale, $element), $breakpoint), $property);
}

// Mixin to apply typography styles
@mixin typography($element) {
    font-size: get-typography-value($element, 'font-size');
    font-weight: get-typography-value($element, 'weight');
    letter-spacing: get-typography-value($element, 'tracking');
    line-height: get-typography-value($element, 'line-height');
  
    @if map-has-key(map-get($typography-scale, $element), 'tablet') {
        @media (min-width: $tablet) {
            font-size: get-typography-value($element, 'font-size', 'tablet');
            font-weight: get-typography-value($element, 'weight', 'tablet');
            letter-spacing: get-typography-value($element, 'tracking', 'tablet');
            line-height: get-typography-value($element, 'line-height', 'tablet');
        }
    }
  
    @if map-has-key(map-get($typography-scale, $element), 'desktop') {
        @media (min-width: $desktop) {
            font-size: get-typography-value($element, 'font-size', 'desktop');
            font-weight: get-typography-value($element, 'weight', 'desktop');
            letter-spacing: get-typography-value($element, 'tracking', 'desktop');
            line-height: get-typography-value($element, 'line-height', 'desktop');
        }
    }
}

@each $element, $styles in $typography-scale {
    .cg-#{$element} {
        @include typography($element);
    }
}
