.mb-6 {
    margin-bottom: 5rem;
}

.mt-6 {
    margin-top: 5rem;
}

.me-6 {
    margin-right: 5rem;
}

.ms-6 {
    margin-left: 5rem;
}

.my-6 {
    margin-bottom: 5rem;
    margin-top: 5rem;
}

.m-6 {
    margin: 5rem;
}

.mx-6 {
    margin-left: 5rem;
    margin-right: 5rem;
}

.pb-6 {
    padding-bottom: 5rem;
}

.pt-6 {
    padding-top: 5rem;
}

.pe-6 {
    padding-right: 5rem;
}

.ps-6 {
    padding-left: 5rem;
}

.ps-lg-2-half {
    @media (width >= $desktop) {
        padding-left: 12px !important;
    }
}

.py-6 {
    padding-bottom: 5rem;
    padding-top: 5rem;
}

.px-6 {
    padding-left: 5rem;
    padding-right: 5rem;
}

.px-7 {
    padding-left: 7rem;
    padding-right: 7rem;
}

.p-6 {
    padding: 5rem;
}

.mt-5_5 {
    margin-top: 1.5rem !important;
}

.h-0 {
    height: 0 !important;
}

.w-0 {
    width: 0 !important;
}

.w-80 {
    width: 80% !important;
}

.w-60 {
    width: 60% !important;
}

.w-40 {
    width: 40% !important;
}

.w-35 {
    width: 35% !important;
}

.w-20 {
    width: 20% !important;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.text-justify {
    text-align: justify !important;
}

.object-position-top {
    object-position: top !important;
}

@media (max-width: $tablet) {
    .mt-standard {
        margin-top: 40px !important;
    }

    .mb-standard {
        margin-bottom: 40px !important;
    }

    .my-standard {
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .my-banner-standard {
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .pt-standard {
        padding-top: 40px !important;
    }

    .pb-standard {
        padding-bottom: 40px !important;
    }

    .py-standard {
        padding-bottom: 40px;
        padding-top: 40px;
    }

    .ms-sm-6 {
        margin-left: 5rem;
    }
}

@media (min-width: $phone) {
    .col-sm {
        flex: 1 0 0% !important;
    }
}

@media (min-width: $tablet) {
    .p-md-6 {
        padding: 5rem !important;
    }

    .ps-md-6 {
        padding-left: 5rem !important;
    }

    .px-md-6 {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }

    .px-7 {
        padding-left: 7rem !important;
        padding-right: 7rem !important;
    }

    .w-md-75 {
        width: 75% !important;
    }

    .w-md-100 {
        width: 100% !important;
    }

    .mt-standard {
        margin-top: 60px !important;
    }

    .mb-standard {
        margin-bottom: 60px !important;
    }

    .my-standard {
        margin-bottom: 60px;
        margin-top: 60px;
    }

    .my-banner-standard {
        margin-bottom: 60px;
        margin-top: 60px;
    }

    .pt-standard {
        padding-top: 60px !important;
    }

    .pb-standard {
        padding-bottom: 60px !important;
    }

    .py-standard {
        padding-bottom: 60px;
        padding-top: 60px;
    }

    .col-md {
        flex: 1 0 0% !important;
    }

    .position-md-absolute {
        position: absolute !important;
    }
}

@media (min-width: $desktop) {
    .col-lg-1-5 {
        flex: 0 0 auto;
        width: 12.4444444444%;
    }

    .w-lg-75 {
        width: 75% !important;
    }

    .w-lg-50 {
        width: 50% !important;
    }

    .w-lg-100 {
        width: 100% !important;
    }

    .mb-lg-6 {
        margin-bottom: 5rem !important;
    }

    .mt-lg-6 {
        margin-top: 5rem !important;
    }

    .pe-lg-6 {
        padding-right: 5rem !important;
    }

    .py-lg-6 {
        padding-bottom: 5rem !important;
        padding-top: 5rem !important;
    }

    .pb-lg-6 {
        padding-bottom: 5rem !important;
    }

    .mt-standard {
        margin-top: 80px !important;
    }

    .mb-standard {
        margin-bottom: 80px !important;
    }

    .my-standard {
        margin-bottom: 80px;
        margin-top: 80px;
    }

    .my-banner-standard {
        margin-bottom: 80px;
        margin-top: 80px;
    }

    .pt-standard {
        padding-top: 80px !important;
    }

    .pb-standard {
        padding-bottom: 80px !important;
    }

    .py-standard {
        padding-bottom: 80px;
        padding-top: 80px;
    }

    .mt-5_5 {
        margin-top: 3.8rem !important;
    }

    .px-lg-6 {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }

    .col-lg {
        flex: 1 0 0% !important;
    }
}

@media (min-width: $widescreen) {
    .px-xl-6 {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }
}
