@forward '@chicksgroup/styles/animations';
@use "sass:map";
@use 'styles/cg-grid' as grid;
@import 'fonts';
@import '~bootstrap/scss/bootstrap';
@import '~glider-js/glider.min.css';
@import 'mdc-styles';
@import 'variables';
@import 'colors';
@import 'animations';
@import 'typography';
@import 'additional-bootstrap-classes';
@import 'input-states';
@import 'policy-styles';
@import 'md-overrides';
@import 'mixins';
@import 'cg-core-lib';
@import 'typography_4x';
@import 'cds-overrides';

body {
    background: $secondarybackground;
    color: $primarycolor;
    font-family: $primaryfont;
    font-size: $regular;
    letter-spacing: 0.68px;
    overflow: hidden;

    &.mdc-dialog-scroll-lock {
        .simplebar-mask {
            z-index: unset;
        }
    }

    @media (max-width: $desktop) {
        overflow: auto;
    }

    @media (width < $tablet) {
        position: relative;
        width: 100vw;
        height: 100dvh;
    }
}

input {
    caret-color: rgba(224, 225, 226, 1) !important;
}

#main-page-host>div>div>div>div>div.simplebar-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.page-host {
    height: 100vh;
    overflow: auto;
    overflow-y: overlay;

    @media (width < $tablet) {
        @include square(100%);
        position: absolute !important;
        top: 0;
        left: 0;
    }
}

.p-regular {
    padding: $regularPad;
}

.mt-big {
    margin-top: $bigPad;
}

.mb-regular {
    margin-bottom: $regularPad !important;
}

.mb-big {
    margin-bottom: $bigPad;
}

.mt-regular {
    margin-top: $regularPad;
}

.ml-regular {
    margin-left: $regularPad;
}

.mt-small-plus {
    margin-top: $smallPadPlus;
}

.mt-big-pad {
    margin-top: $bigPad !important;
}

.p-regular {
    padding: $regularPad !important;
}

.gap-regular {
    gap: $regularPad;
}

.gap-big {
    gap: $bigPad;
}

.gap-small {
    gap: $smallPad;
}

.element-dashed {
    border: 2px $mediumgray dashed;
    border-radius: 4px;
}

.simplebar-scrollbar::before {
    background-color: rgba(87, 98, 213, 0.8) !important;
    right: 3px !important;
}

.simplebar-scrollbar.simplebar-hover::before {
    background-color: $blue !important;
}

.simplebar-dragging .simplebar-scrollbar.simplebar-hover::before {
    background-color: rgba(67, 75, 163, 1) !important;
}

.simplebar-scrollbar.simplebar-visible::before {
    opacity: 1 !important;
}

.simplebar-offset {
    -webkit-overflow-scrolling: unset !important;
}

section[data-simplebar],
div[data-simplebar]:not(.page-host) {
    max-height: 350px;
    overflow: auto;
    overflow-y: overlay;

    @media (max-width: $desktop) {
        max-height: 250px;
    }
}

div[data-simplebar].page-host {
    height: 100vh;
    overflow: auto;
    overflow-y: overlay;

    @media (width < $tablet) {
        height: 100%;
    }
}

.gray-scrollbar .simplebar-scrollbar::before {
    background-color: $mediumgray !important;
}

.gray-scrollbar .simplebar-scrollbar.simplebar-hover::before {
    background-color: $gray !important;
}

.gray-scrollbar .simplebar-dragging .simplebar-scrollbar.simplebar-hover::before {
    background-color: $darkgray !important;
}

a,
a:link,
a:visited,
a:hover,
a:active,
u {
    color: unset;
    text-decoration: none;
}

.toast-top-right {
    top: 100px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill::first-line {
    box-shadow: 0 0 0 1000px rgba(54, 58, 79, 1) inset;
    color: $white !important;
    -webkit-text-fill-color: $white !important;
    font-family: $primaryfont !important;
    font-size: 1rem !important;
}

.blue-background-container input:-webkit-autofill,
.blue-background-container input:-webkit-autofill:hover,
.blue-background-container input:-webkit-autofill:focus,
.blue-background-container input:-webkit-autofill:active,
.blue-background-container input:-webkit-autofill::first-line {
    box-shadow: 0 0 0 1000px rgba(43, 48, 77, 1) inset;
    color: $white !important;
    -webkit-text-fill-color: $white !important;
    font-family: $primaryfont !important;
    font-size: 1rem !important;
}

@-moz-document url-prefix() {

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-webkit-autofill::first-line {
        box-shadow: none !important;
    }

    .cg-input--success,
    .cg-input--error,
    .cg-input--warning {

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        input:-webkit-autofill::first-line {
            box-shadow: none !important;
        }
    }
}

.invalid-feedback {
    font-size: $regular;

    @media (max-width: $desktop) {
        font-size: $small;
    }
}

.main-content-block {
    background: $secondarybackground;
    //TODO: Remove when new design for services is implemented
    border: 1px solid $transparent;
    border-radius: 5px;
}

.border-primary {
    border: 1px solid $primaryborder !important;
}

.main-content-border {
    border: $regular-border !important;
    border-radius: 4px;
}

.product-image {
    max-height: 100%;
    max-width: 100%;
    max-height: 40px;
}

.main-content-padding {
    margin-top: 60px;
    padding-bottom: 80px;
    padding-top: 80px;
}

@media (max-width: $desktop) {
    .main-content-padding {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .main-content-padding-second {
        margin-top: 72px;
        padding: 0;
    }
}

@media (max-width: $phone) {
    .main-content-padding {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.blue-background {
    background: $secondarybackground;
    border-radius: 5px;
}

@for $i from 1 through 10 {
    $tenth: $i * 10;
    .opacity-#{$tenth} {
       opacity: calc($i / 10);
    }
}

.primary-background {
    background: $primarybackground;
    border-radius: 4px;
}

.secondary-background {
    background: $secondarybackground;
    border-radius: 4px;
}

.dropdown-primary-background {
    background: $dropdownprimarybackground;
    border-radius: 4px;
}

.dropdown-secondary-background {
    background: $dropdownsecondarybackground;
    border-radius: 4px;
}

.white-border {
    border: 1px solid $mediumgray;
}

.transparent-border {
    border: 1px solid $transparent;
    border-radius: 4px;
}

.blue-border {
    border: 1px solid $primaryborder;
    border-radius: 4px;
}
.accent-border-top {
    border-top: 2px solid $primaryborder;
}
.blue-border-top {
    border-top: 2px solid $primaryborder;
}

.blue-border-bottom {
    border-bottom: 2px solid $primaryborder;
}

.blue-border-bottom--thin {
    border-bottom: 1px solid $primaryborder;
}

.blue-border-right {
    border-right: 1px solid $primaryborder;
}

.accent-border-right {
    border-right: 1px solid $color-accent-500;
}

.darkblue-border-bottom {
    box-shadow: 0 17px 0 -16px rgb(76 96 133 / 50%);
}

.gray-border-top {
    border-top: 1px solid $darkergray3;
}

.button-text {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    text-align: left;
}

.button-dark-filled {
    background-color: $soft-white !important;
    box-shadow: none !important;
}

.button-text-disabled {
    cursor: default;
    opacity: 0.2;
}

.submit-button {
    height: 50px;
    width: 100%;
}

@media (min-width: $extralargescreen) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 2366px;
    }
}

@media (min-width: $widescreen) and (max-width: $extralargescreen) {
    #{$containers} {
        max-width: 1260px;
    }
}

.grid-container {
    max-width: $grid-width-desktop;
    margin: 0 auto;
    padding-inline: $regularPad;
    box-sizing: content-box;

    @media (width >= $extralargescreen) {
        max-width: 2366px;
    }
}

@media (min-width: $extrascreen) and (max-width: $extralargescreen) {
    //TODO: Remove when new design for services is implemented
    #services .container,
    #services .container-lg,
    #services .container-md,
    #services .container-sm,
    #services .container-xl,
    #services .container-xxl {
        max-width: 1260px !important;
    }

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: calc($grid-width-desktop + 24px);
    }
}

@media (min-width: $tablet) and (max-width: $desktop) {
    .container {
        max-width: 754px;
    }
}

@media (max-width: $phone) {
    .container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.chip {
    background-color: $primarybackground;
    border-radius: 4px;
    color: $gray;
    padding: 10px;
}

::-webkit-scrollbar {
    display: none;
    width: 20px;
}

textarea::-webkit-scrollbar {
    display: unset;
}

textarea::-webkit-scrollbar {
    width: 20px;
}

textarea::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: rgba(87, 98, 213, 0.8);
    border: 7px solid $transparent;
    border-radius: 20px;

    &:hover {
        background-color: $blue;
    }

    &:active {
        background-color: rgba(67, 75, 163, 1);
    }
}

.horizontal-scroll-filters {
    display: flex;
    height: 83vh;
    width: 100%;
    overflow-x: scroll;

    @include media($tablet) {
        height: 92vh;
    }

}

.horizontal-scroll-filters::-webkit-scrollbar {
    display: none;
}

.tablet-filters {
    height: 50px;
    position: relative;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.close-icon {
    color: $white;
    cursor: pointer;
    font-size: $medium;
    top: 17px;
    right: 20px;
    z-index: 20;

    @media (max-width: $phone) {
        &.close-icon-phone-input {
            padding: 10px;
            top: 7px !important;
        }
    }
}

::-webkit-scrollbar-track {
    background-color: $transparent;
}

::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: rgba(87, 98, 213, 0.8);
    border: 7px solid $transparent;
    border-radius: 20px;
}

::-ms-reveal {
    display: none;
}

.divisor-primary-blue {
    border-color: $slateblue-off-low-opacity;
}

.btn {
    border-right: $transparent !important;
    border-color: unset !important;
    border: unset !important;
}

p {
    color: $gray;
    font-family: $secondaryfont;
    line-height: 2;
    margin-bottom: 1.5rem !important;
}

.z-9999 {
    z-index: 9999;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $primaryfont !important;
}

.header-container {
    max-width: 1260px;
    padding-bottom: 224px;
    padding-top: 14%;

    @media (max-width: $desktop) {
        padding-top: 137px;
    }

    @media (max-width: $widescreen) {
        padding-bottom: 8%;
    }

    .header-secondary-text {
        font-family: $secondaryfont;
        letter-spacing: 0.77px;
        line-height: 1.43;
    }
}

@media print {
    .no-print {
        display: none;
    }
}

.area-img {
    height: fit-content;
    max-height: 450px;
    width: fit-content;
}

@media (max-width: $tablet) {
    .area-img {
        max-height: 400px;
    }
}

@media (max-width: $phone) {
    .area-img {
        max-height: 350px;
    }
}

.drawer {
    background-color: $dropdownprimarybackground;
    top: 0;
    width: 260px;

    .menu-container {
        border-bottom: 2px solid $color-accent-500;

        .menu-button {
            font-size: $small;
            text-transform: capitalize;
        }
    }

    .drawer-button-deep {
        background-color: $primarybackground !important;
    }

    .drawer-button {
        border-bottom: 2px solid $primarybackground;
        height: 50px;

        a {
            outline: 0;
        }
    }
}

.font-arrow {
    max-width: 11px;
    max-height: 7px;
    margin-left: 4.5px;

    @media (max-width: $desktop) {
        max-width: 9px;
    }
}

.switch {
    position: relative;
    display: inline-block;
    @include size(44px, 24px);
}

.switch input {
    opacity: 0;
    @include square(0);
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(94, 90, 107);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    $size: 16px;
    @include square($size);
    position: absolute;
    content: "";
    top: 50%;
    right: calc(100% - ($size + 4px));
    transform: translateY(-50%);
    background-color: rgb(219, 218, 222);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: rgb(171, 176, 234);
}

input:focus+.slider {
    box-shadow: 0 0 1px rgba(76, 96, 133, .54);
}

input:checked+.slider:before {
    background-color: var(--Primary-700);
    right: 4px;
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.quantity-field:hover #cg-number-arrows,
.quantity-field:hover #osrs-arrows,
.quantity-field:hover #rs3-arrows,
.quantity-field:hover .circle-icon {
    visibility: visible;
    opacity: 1;
}

.show-green-checkmark-hover {
    visibility: visible;
    opacity: 1;
    transition: .1s all ease-in-out;
}

.quantity-field:hover .show-green-checkmark-hover,
.quantity-field:hover .circle-icon {
    visibility: hidden;
    opacity: 0;
}

.mdc-dialog {
    z-index: 1040;
}

.balance-icon {
    font-size: $largeplus;
}

.g-transition {
    transition: 0.3s;
}

.global-arrow-icon {
    border: 0;
    cursor: pointer;
    transition: 0.3s ease;
    @include disable-select();
    @include size(12px, 6px);
    @include flex-center();
}

.button-size {
    width: 318px;

    @media (max-width: $desktop) {
        width: 100%;
    }
}

.container-portal-style {
    background: $secondarybackground;
    border-radius: 4px;
    padding: 50px;

    @media (max-width: $desktop) {
        padding: 30px;
    }

    @media (max-width: $phone) {
        padding: 20px;
    }
}

.container-portal-style-order {
    background: $secondarybackground;
    border-radius: 4px;
    padding: 50px;

    @media (max-width: $desktop) {
        padding: 30px;
    }

    @media (max-width: $phone) {
        padding: 20px !important;
    }
}

.blue-circle-number {
    background-color: $blue;
    border-radius: 50%;
    height: 27px;
    margin-right: 13px;
    padding: 3px;
    padding-left: 5px;
    text-align: center;
    width: 27px;
}

.text-backface-visibility {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
}

.category-functional-container,
.template-content-separation {
    margin-bottom: 80px;

    @media (max-width: $tablet) {
        margin-bottom: 60px;
    }

    @media (max-width: $phone) {
        margin-bottom: 40px;
    }
}

.template-content-bottom {
    margin-bottom: 40px;
}

.template-content-services {
    margin-bottom: 80px;

    @media (max-width: $tablet) {
        margin-bottom: 60px;
    }

    @media (max-width: $phone) {
        margin-bottom: 40px;
    }
}

.template-border-bottom-line {
    padding-bottom: 80px;

    @media (max-width: $tablet) {
        padding-bottom: 60px;
    }

    @media (max-width: $phone) {
        padding-bottom: 40px;
    }

    border-bottom: 2px solid $primaryborder;
}

.template-border-bottom-line:has(.aurelia-hide) {
    display: none !important;
}

.icon-white {
    color: $color-grey-100;
}

.template-border-bottom-line-override {
    border-bottom: unset !important;
}

.grecaptcha-badge {
    visibility: hidden;
}

.admin-view-size {
    font-size: $extrasmall;
}

/*
    Defined global styling for mdc-select to rotate arrow up/down depending on dropdown status
*/
mdc-select {
    &.mdc-select--activated .global-arrow-icon {
        transform: rotate(180deg) !important;
    }

    .global-arrow-icon {
        transform: rotate(0deg) !important;
    }
}

.mdc-menu-surface--anchor:has(.mdc-menu-surface--open) .global-arrow-icon,
.mdc-text-field--focused .global-arrow-icon,
.global-arrow-icon.global-arrow-icon__rotated {
    transform: rotate(180deg) !important;
}

.pay-button {
    margin-left: 5px !important;

    @media (width <= $widescreen) {
        margin-left: unset !important;
    }
}

/*
    Google Pay Button
*/
.cg-google-pay-box {
    height: 50px;

    button {
        border: none !important;
        outline: none !important;
        color: $white !important;
        background: $blue !important;
        font-family: $primaryfont !important;
        border-radius: 4px !important;
        font-size: $regular !important;
        font-weight: 500 !important;
        letter-spacing: .15px !important;
        will-change: transform, opacity !important;
        min-width: unset !important;

        &:hover {
            background: $hoverblue !important;
        }

        @media (max-width: $widescreen) {
            margin-left: unset !important;
            max-width: unset !important;
        }

        @media (max-width: calc($phone - 1px)) {
            border-radius: 10px !important;
            font-size: $medium !important;
        }
    }
}

/*
    Apple Pay Button
*/
apple-pay-button {
    --apple-pay-button-width: 254px;
    --apple-pay-button-height: 50px;
    --apple-pay-button-border-radius: 4px;
    --apple-pay-button-padding: 8px 0px;
    --apple-pay-button-box-sizing: border-box;
    margin-left: 5px !important;
    max-width: 254px;
    width: 100%;
    min-width: unset !important;

    @media (width <= $widescreen) {
        margin-left: unset !important;
        max-width: unset !important;
    }

    @include media-exclusive($phone, true) {
        border-radius: 10px !important;
    }
}

.disabled-gpay-button button,
.disabled-apple-pay-button apple-pay-button {
    opacity: .2;
    pointer-events: none;
}

.arrow-position-below {
    margin-top: -5px;

    &::after {
        top: 100% !important;
        transform: rotate(180deg) !important;
    }
}

.custom-tooltip-above {
    bottom: 50px;
}

.custom-tooltip-end {
    right: 0;
}

.arrow-position-start::after {
    left: 90%;
}

.arrow-position-end::after {
    left: 10%;
}

.arrow-vip-right::after {
    top: 50%;
    left: calc(44% + 5px);
    transform: rotate(90deg);
}

.arrow-position-left-offset::after {
    left: 4%;
}

.arrow-vip-left::after {
    top: 50%;
    left: calc(3% + 5px);
    transform: rotate(-90deg);
}

.arrow-position-below {
    margin-top: -5px;

    &::after {
        top: 100% !important;
        transform: rotate(180deg) !important;
    }
}
.object-fit-contain {
    object-fit: contain;
}

.material-icons,
.material-icons-outlined,
.material-icons-round {
    transform: rotate(0.03deg);
}

.bread-effect {
    &:hover {
        font-weight: 500;
    }

    @include ul-hover();
}

.default-anchor {
    @include default-anchor();
}

.ap-dynamic-content {
    @include default-anchor();
}

figure {
    margin: 0;
}

@each $value in 'pointer', 'default', 'wait', 'not-allowed', 'auto', 'text', 'move', 'help' {
    .cursor-#{$value} {
        cursor: #{$value};
    }
}

.circle-icon {
    position: absolute;
    top: 15px;
    right: 15px;
}

.floating-bottom-bar-service {
    background-color: $secondarybackground;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    padding: 0 20.5px;
    font-size: $medium;
    font-weight: 500;
    z-index: 999;
}

.products-header {
    margin-inline: 15px 30px;
    justify-content: space-between;
    align-items: center;

    @media (width <=$desktop) {
        margin-right: 35px;
    }

    >div {
        font-size: $small;
        font-weight: normal;
        line-height: .75rem;
        letter-spacing: 0.384px;
        opacity: 0.5;
        flex: 1;

        &:first-child {
            max-width: 200px;
        }

        &:not(:first-child) {
            max-width: 105px;

            @media (width <=$desktop) {
                max-width: 6.75rem;
            }
        }
    }
}

@for $i from 1 through 9 {
    $hundred: $i * 100;

    .fw-#{$hundred} {
        font-weight: $hundred;
    }
}

.force-upwards {
    transform-origin: center bottom !important;
    top: unset !important;
    bottom: 50px !important;
    max-height: unset !important;

    div[data-simplebar] {
        max-height: 350px !important;
    }
}

cg-bottom-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    @include size(100%, 200px);
    background-color: $offblack;
    transform: translateY(100%);
    transition: height 0.4s ease-out;
    z-index: 999;
    border-radius: 32px 32px 0px 0px;
}

.cg-notice-box {
    background-color: $slateblue-off-low-opacity;
    border-radius: 4px;
    border-left: 3px solid $primaryborder;
    padding: 20px;
    @include default-anchor();

    p {
        font-family: $primaryfont;
        line-height: $medium;
        letter-spacing: .014px;
    }
}

.products-header {
    margin-inline: 15px 30px;
    justify-content: space-between;
    align-items: center;

    @media (width <= $desktop) {
        margin-right: 35px;
    }

    > div {
        font-size: $small;
        font-weight: normal;
        line-height: .75rem;
        letter-spacing: 0.384px;
        opacity: 0.5;
        flex: 1;

        &:first-child {
            max-width: 276px;

            &.is-balance {
                max-width: 200px;
            }

            @media (width <= $desktop) {
                max-width: 200px;
            }
        }

        &:not(:first-child) {
            max-width: 105px;

            &.balance-date {
                max-width: 130px;
            }

            @media (width <= $desktop) {
                max-width: 6.75rem;
            }
        }

        @media ($widescreen <= width) {
            &:nth-child(3) {
                max-width: 144px;

                &.is-balance {
                    max-width: 125px;
                }
            }

            &:nth-child(4) {
                max-width: 142px;
            }
        }
    }
}

@for $i from 1 through 9 {
    $hundred: $i * 100;
    .fw-#{$hundred} {
        font-weight: $hundred;
    }
}

.disable-select {
    @include disable-select();
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-center {
    @include flex-center();
}

.background-custom-styling {
    top: 72px !important;

    &.osrs-background-styling {
        width: 115% !important;
        max-height: 100vh;
        object-fit: cover;
        object-position: top;

        @media (width <= $phone) {
            top: 36px !important;
        }
    }
}

@for $i from 0 through 10 {
    .z-#{$i} {
      z-index: $i;
    }
}

.hidden {
    height: 0 !important;

    &__bounce {
        margin-top: -10px !important;
    }
}

$align: (
    "initial": initial,
    "start": flex-start,
    "end" : flex-end,
    "center": center,
    "baseline": baseline,
    "stretch": stretch
);

$justify: (
    "initial": initial,
    "start": flex-start,
    "end" : flex-end,
    "center": center,
    "between" : space-between,
    "around": space-around
);

@each $key, $value in $align {
    @each $key2, $value2 in $justify {
      .flex-#{$key}-#{$key2} {
          @include flex($value, $value2);
      }
    }
}

.box-shadow-unset {
    box-shadow: unset !important;
}

@for $i from 1 through 5 {
    .flex-#{$i} {
        flex: #{calc(100 / $i)}#{'%'} !important;
    }
}

@media (width <= $desktop) {
    .temporarily-service-styling {
        max-width: 100vw;
        padding-left: 10px;
        padding-right: 10px;
    }

    .temporarily-background-site {
        background-color: $secondarybackground !important;
        overflow-x: hidden !important;
        padding-top: 99px !important;
    }
}

.circular-background {
    background: linear-gradient(to left, rgba(55, 65, 79, 1) 50%, $transparent 100%);
    width: 40px;
    position: absolute;
    height: 20px;
    bottom: 12px;
    right: 15px;
    opacity: 1;
    visibility: visible;
}

.quantity-field:hover .circular-background {
    opacity: 0;
    visibility: hidden;
}

.circle-icon-textarea {
    bottom: 14px;
    top: unset !important;

    &-alternative {
        @include centerContainer(16px, true);
    }
}

#intercom-facade-btn {
    background-color: $blue !important;
}

.ul-hover {
    @include ul-hover(true);
}
.font-size-inherit {
    font-size: inherit;
}

.text-green {
    color: $green;
}

@for $i from 1 through 5 {
    .truncate-text-#{$i} {
        @include truncate-text($i);
    }

    .truncate-text-#{$i}-b {
        @include truncate-text($i, true);
    }
}

.d-contents {
    display: contents !important;
}

.regular-border {
    @include regular-border();
}

.regular-border-static {
    @include regular-border(false);
}

.cg-grid {
    @include grid.cg-grid($include-resolutions: true, $include-classes: true);
}

.profile-subtitle {
    @extend .profile-subtitle-extend
}

.page-host {
    @include flex-column(initial !important, initial !important);
}

.page-host-content {
    flex: 1;
    @include flex(stretch, center);

    > * {
        width: 100%;
    }
}

::root {
    --skeletonWidth: auto;
    --skeletonHeight: auto;
}

@keyframes skeletonFrames {
    0% {
      opacity: 0.2;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.2;
    }
}

.skeleton-loading {
    background-color: $slateblue-off-low-opacity;
    border-radius: 4px;
    animation: skeletonLoading .5s linear infinite alternate;

    * {
        visibility: hidden !important;
    }
}

.skeletonWidth {
    width: var(--skeletonWidth) !important;
}

.skeletonHeight {
    height: var(--skeletonHeight) !important;
}

.hidden-skeleton {
    display: none !important;
}

.unset-opacity {
    opacity: unset !important;
}
