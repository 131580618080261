.cg-input--error {
    background: rgba(253, 74, 74, 0.08) !important;
    border: 0 !important;
    border-radius: 4px !important;

    &:hover:not(.mdc-text-field--focused) {
        background: rgba(253, 74, 74, 0.12) !important;

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        input:-webkit-autofill::first-line {
            box-shadow: 0 0 0 30px rgba(73, 52, 66, 1) inset !important;
        }

        &.blue-background-container input:-webkit-autofill,
        &.blue-background-container input:-webkit-autofill:hover,
        &.blue-background-container input:-webkit-autofill:focus,
        &.blue-background-container input:-webkit-autofill:active,
        &.blue-background-container input:-webkit-autofill::first-line {
            box-shadow: 0 0 0 30px rgba(61, 40, 64, 1) inset !important;
        }
    }

    &.mdc-text-field--focused {
        background: rgba(253, 74, 74, 0.16) !important;

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        input:-webkit-autofill::first-line {
            box-shadow: 0 0 0 30px rgba(81, 53, 67, 1) inset !important;
        }

        &.blue-background-container input:-webkit-autofill,
        &.blue-background-container input:-webkit-autofill:hover,
        &.blue-background-container input:-webkit-autofill:focus,
        &.blue-background-container input:-webkit-autofill:active,
        &.blue-background-container input:-webkit-autofill::first-line {
            box-shadow: 0 0 0 30px rgba(69, 42, 65, 1) inset !important;
        }
    }

    .form-file-text {
        color: rgba(253, 74, 74, 1) !important;
    }

    .mdc-text-field,
    .mdc-text-field__input,
    .mdc-select .mdc-text-field--input,
    .mdc-select__selected-text {
        color: rgba(255, 255, 255, 0.75) !important;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
        border-color: $transparent !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-webkit-autofill::first-line {
        box-shadow: 0 0 0 30px rgba(65, 51, 66, 1) inset !important;
        color: rgba(225, 225, 225, 0.75) !important;
        -webkit-text-fill-color: rgba(225, 225, 225, 0.75) !important;
        font-family: $primaryfont !important;
        font-size: 1rem !important;
    }
    
    &.blue-background-container input:-webkit-autofill,
    &.blue-background-container input:-webkit-autofill:hover,
    &.blue-background-container input:-webkit-autofill:focus,
    &.blue-background-container input:-webkit-autofill:active,
    &.blue-background-container input:-webkit-autofill::first-line {
        box-shadow: 0 0 0 30px rgba(52, 39, 64, 1) inset !important;
        color: rgba(225, 225, 225, 0.75) !important;
        -webkit-text-fill-color: rgba(225, 225, 225, 0.75) !important;
        font-family: $primaryfont !important;
        font-size: 1rem !important;
    }
}

.cg-input--warning {
    background: rgba(254, 215, 102, 0.05) !important;
    border: 0 !important;
    border-radius: 4px !important;

    &:hover:not(.mdc-text-field--focused) {
        background: rgba(254, 215, 102, 0.09) !important;
    }

    &.mdc-text-field--focused {
        background: rgba(254, 215, 102, 0.13) !important;
    }

    .form-file-text {
        color: rgba(253, 215, 102, 1) !important;
    }
    
    .mdc-text-field,
    .mdc-text-field__input,
    .mdc-text-field__input::placeholder,
    .mdc-select .mdc-text-field--input,
    .mdc-select__selected-text {
        color: rgba(255, 255, 255, 0.75) !important;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
        border-color: $transparent !important;
    }
}

.cg-input--success {
    background: $darkergreen !important;
    border: 0 !important;
    border-radius: 4px !important;
    
    &:hover:not(.mdc-text-field--focused) {
        background: rgba(57, 226, 157, 0.09) !important;

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        input:-webkit-autofill::first-line {
            box-shadow: 0 0 0 30px rgba(50, 65, 73, 1) inset !important;
        }

        &.blue-background-container input:-webkit-autofill,
        &.blue-background-container input:-webkit-autofill:hover,
        &.blue-background-container input:-webkit-autofill:focus,
        &.blue-background-container input:-webkit-autofill:active,
        &.blue-background-container input:-webkit-autofill::first-line {
            box-shadow: 0 0 0 30px rgba(37, 53, 71, 1) inset !important;
        }
    }

    &.mdc-text-field--focused {
        background: rgba(57, 226, 157, 0.13) !important;

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        input:-webkit-autofill::first-line {
            box-shadow: 0 0 0 30px rgba(50, 72, 77, 1) inset !important;
        }

        &.blue-background-container input:-webkit-autofill,
        &.blue-background-container input:-webkit-autofill:hover,
        &.blue-background-container input:-webkit-autofill:focus,
        &.blue-background-container input:-webkit-autofill:active,
        &.blue-background-container input:-webkit-autofill::first-line {
            box-shadow: 0 0 0 30px rgb(36, 61, 75, 1) inset !important;
        }
    }

    .form-file-text {
        color: $green !important;
    }

    .mdc-text-field,
    .mdc-text-field__input,
    .mdc-select .mdc-text-field--input,
    .mdc-select__selected-text {
        color: rgba(225, 225, 225, 0.75) !important;
    }

    .mdc-text-field__input:focus::placeholder {
        color: $transparent !important;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
        border-color: $transparent !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-webkit-autofill::first-line {
        box-shadow: 0 0 0 30px rgba(49, 59, 70, 1) inset !important;
    }

    &.blue-background-container input:-webkit-autofill,
    &.blue-background-container input:-webkit-autofill:hover,
    &.blue-background-container input:-webkit-autofill:focus,
    &.blue-background-container input:-webkit-autofill:active,
    &.blue-background-container input:-webkit-autofill::first-line {
        box-shadow: 0 0 0 30px rgba(35, 46, 68, 1) inset !important;
        color: rgba(225, 225, 225, 0.75) !important;
        -webkit-text-fill-color: rgba(225, 225, 225, 0.75) !important;
        font-family: $primaryfont !important;
        font-size: 1rem !important;
    }
}
