@use 'styles/variables' as var;
@use 'styles/mixins' as mix;

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.bottom-sheet {
    z-index: 100;
}

:root {
    --trust-pilot-icon-height: 32px;
    --large-button-skeleton: 72px;
    --cds-small-pad: #{var.$smallPad};
    --cds-small-pad-plus: #{var.$smallPadPlus};
    --cds-regular-pad: #{var.$regularPad};
    --cds-medium-pad: #{var.$mediumPad};
    --cds-toast-top-margin: 98px;
    --cds-secondary-background: #{var.$secondarybackground};

    --cds-bottom-sheet-handlebar-width: 56px;
    --cds-bottom-sheet-handlebar-height: 4px;
    --cds-bottom-sheet-width: 100%;

    --cds-snackbar-text-color: #{var.$color-grey-300};
    --cds-snackbar-bottom-padding: 24px;
    --cds-snackbar-horizontal-padding: 11px;
    body:has(#cart, #services, #swap, #unique-names, #sell) {
        --cds-snackbar-bottom-padding: 84px;
    }

    --cds-bottom-sheet-background-color: #{var.$color-accent-700};
    --cds-bottom-sheet-handlebar-color: #{var.$line-color-after-content};

    --cds-primary-font: #{var.$primaryfont};
    --cds-large-text: #{var.$large};
    --cds-medium-text: #{var.$medium};

    --cds-global-shape: #{var.$thinborder};
    --sheet-start-height: 140px;
    --sheet-height: 140px;

    cds-toast {
        top: 70px;
        position: relative;
    }

    cds-social-circles {
        --cds-social-circles-background-color: #4A53B5;
        --cds-social-circles-background-color-hover: #5762D5;
        --cds-social-circles-background-color-pressed: #717BE6;
    }

    --md-sys-color-primary: #{var.$blue};

    --Color-Grey-1000: #{var.$color-grey-1000};
    --Color-Grey-700: #{var.$color-grey-700};
    --Color-Grey-600: #{var.$color-grey-600};
    --Color-Grey-500: #{var.$color-grey-500};
    --Color-Grey-300: #{var.$color-grey-300};
    --Color-Grey-200: #{var.$color-grey-200};
    --Color-Grey-100: #{var.$color-grey-100};

    --Color-Accent-700: #{var.$color-accent-700};
    --Color-Accent-600: #{var.$color-accent-600};
    --Color-Accent-500: #{var.$color-accent-500};

    --Primary-700: #{var.$primary-700};
    --Primary-300: #{var.$primary-300};
    --Primary-200: #{var.$primary-200};

    --Secondary-900: #{var.$secondary-900};
    --Secondary-600: #{var.$secondary-600};
    --Secondary-200: #{var.$secondary-200};
    --Secondary-100: #{var.$secondary-100};

    --cds-primary-background: #{var.$offblack};
    --cds-dynamic-banner-min-width-button: 332px;
    --cds-dynamic-banner-height-button: 52px;

    --cds-dynamic-banner-image-container-margin: 0;
    --cds-dynamic-banner-margin-description: 0;
    --cds-dynamic-banner-description-color: #{var.$gray};

    cds-dynamic-banner {
        --md-filled-button-label-text-size: 16px;
    }

    @media (width < var.$desktop) {
        --cds-dynamic-banner-display: flex;
        --cds-dynamic-banner-direction: column-reverse;
        --cds-dynamic-banner-max-width: 542px;
        --cds-dynamic-banner-min-height: 100%;
        --cds-dynamic-banner-max-height: 283px;

        --cds-dynamic-banner-width-content: 272px;
        --cds-dynamic-banner-height-content: 222px;
        --cds-dynamic-banner-margin-content: 0;

        --cds-dynamic-banner-min-height-text-content: 100px;

        --cds-dynamic-banner-container-padding: #{var.$mediumPad};

        --cds-dynamic-banner-title-size: #{var.$largeplus};
        --cds-dynamic-banner-title-line-height: 29px;

        --cds-dynamic-banner-description-size: #{var.$regular};
        --cds-dynamic-banner-description-line-height: #{var.$extralargeplus};

        --cds-dynamic-banner-min-width-button: 100%;
        --cds-dynamic-banner-max-width-button: 272px;

        --cds-dynamic-banner-image-container-margin: 0 0 0 23px;
        --cds-dynamic-banner-image-container-width: 100%;
        --cds-dynamic-banner-image-container-max-width: 184px;
        --cds-dynamic-banner-image-container-height: 223px;
        --cds-dynamic-banner-image-container-justify: center;

        --cds-dynamic-banner-image-width: 184px;
        --cds-dynamic-banner-image-height: 179px;
    }

    @media (width < var.$tablet) {
        --cds-dynamic-banner-max-width: 336px;
        --cds-dynamic-banner-max-height: 432px;

        --cds-dynamic-banner-container-padding: #{var.$regularPad};
        --cds-dynamic-banner-container-direction: column-reverse;

        --cds-dynamic-banner-width-content: 100%;
        --cds-dynamic-banner-min-height-content: 162px;
        --cds-dynamic-banner-height-content: auto;
        --cds-dynamic-banner-max-height-content: 183px;

        --cds-dynamic-banner-min-height-text-content: 63px;

        --cds-dynamic-banner-title-size: #{var.$regular};
        --cds-dynamic-banner-title-line-height: 17px;

        --cds-dynamic-banner-description-size: #{var.$small};
        --cds-dynamic-banner-margin-description: 0 0 #{var.$regularPad} 0;
        --cds-dynamic-banner-description-line-height: 21px;

        --cds-dynamic-banner-max-width-button: 294px;

        --cds-dynamic-banner-image-container-max-width: 180px;
        --cds-dynamic-banner-image-container-height: auto;
        --cds-dynamic-banner-image-container-margin: 0 0 25px 0;
    }
}
